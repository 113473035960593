<template>
  <div v-if="isInitialized" class="order-form-section">
    <ConfirmOrderModal
      :open-modal="openConfirmOrderModal"
      @submit-order="submitOrderCallBack"
      @modal-closed="closeConfirmOrderModal"
    ></ConfirmOrderModal>
    <div class="section-title">
      <span>Order Summary</span>
    </div>
    <div>
      <div class="d-flex">
        <div>
          <label name="order-note-label" class="form-label">Notes</label>
        </div>
        <button
          v-if="!isEditingOrderNote"
          class="btn btn-sm"
          role="button"
          tabindex="0"
          title="Edit Order Note"
          @click="promptOrderNoteEdit"
          @keydown.enter="promptOrderNoteEdit"
        >
          <i
            class="fas fa-pen-to-square"
            role="button"
            tabindex="0"
            title="Edit Order Note"
          />
        </button>

        <div v-if="isEditingOrderNote">
          <button
            class="btn btn-sm"
            role="button"
            tabindex="0"
            title="Save Order Note"
            @keydown.enter="saveCustomerNote"
            @click="saveCustomerNote"
          >
            <i
              class="fas fa-square-check text-success"
              title="Save Order Note"
            />
          </button>
        </div>
      </div>

      <div v-if="isEditingOrderNote" class="note-edit-section mt-2">
        <div class="input-group">
          <textarea
            id="orderNote"
            v-model="orderNote"
            :rows="orderNote ? 3 : 1"
            style="max-width: 500px"
            name="order-note"
            ref="orderNoteRef"
            class="form-control"
            :dir="textDirection"
            autocomplete="off"
            @input="handleOrderNoteChange"
            placeholder="Enter Order note"
          ></textarea>
        </div>
      </div>

      <div v-else>
        <pre :dir="textDirection">{{ orderNote }}</pre>
      </div>

      <div class="d-flex flex-column align-items-start">
        <button
          class="btn btn-outline-dark btn-summary"
          @click="showUploadFileModal"
          :disabled="!isOnline"
          :title="isOnline ? 'Add Attachments' : 'Online connection required'"
        >
          {{ addAtthachmentButtonText }}
          <i class="fas fa-cloud-arrow-up mr-2"></i>
        </button>
        <button
          class="btn btn-success btn-summary"
          @click="saveOrder"
          :disabled="!pdfMakeSetupLoaded"
          :title="saveOrderTitle"
        >
          {{ saveOrderTitle }}
          <i class="mr-2 fas fa-file-pdf" />
        </button>
        <button
          class="btn btn-summary"
          :class="buttonClass"
          @click="showConfirmationModal"
          :disabled="!isOnline || !pdfMakeSetupLoaded"
          :title="submitOrderTitle"
        >
          {{ submitOrderButtonText }}
          <i class="fas fa-paper-plane mr-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  inject,
  computed,
  ref,
  watch,
  onMounted,
  nextTick,
  onBeforeUnmount,
} from "vue";
import { debounce } from "@/utils/utils.js";
import { WorkingItemRepo } from "@/services/DbAccess/WorkingItemRepo";
import { OrderNoteRepo } from "@/services/DbAccess/OrderNoteRepo";
import { OrderService } from "@/services/orderService.js";
import ConfirmOrderModal from "./OrderSummary/ConfirmOrderModal.vue";
import { saveOrderPdfLocally, getOrderPdf } from "@/utils/pdfUtils";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { FileUploadRepo } from "@/services/DbAccess/FileUploadRepo";

const props = defineProps({
  pointOfSaleCode: { type: String, required: true },
  pdfMakeSetupLoaded: { type: Boolean, required: true },
});

const EventBus = inject("EventBus");
const store = useOrderFormStore();
const isOnline = inject("onlineStatus");
const orderNote = ref(null);
const orderNoteRef = ref(null);
const isEditingOrderNote = ref(false);
const isInitialized = ref(false);
const textDirection = ref("ltr");
const openConfirmOrderModal = ref(false);
const addAtthachmentButtonText = ref("Add Attachments");

const saveOrderTitle = computed(() =>
  props.pdfMakeSetupLoaded ? "Save order As Pdf" : "PDF files are missing"
);

const submitOrderTitle = computed(() => {
  if (!isOnline.value) return "No internet connection. Cannot submit order.";
  if (!props.pdfMakeSetupLoaded)
    return "PDF Generation was not loaded. Cannot submit order..";
  return "Submit order to back office";
});

const submitOrderButtonText = computed(() => {
  if (!isOnline.value) return "No internet connection";
  if (!props.pdfMakeSetupLoaded) return "Not Supported";
  return "Submit order";
});

const buttonClass = computed(() =>
  isOnline.value ? "btn-primary" : "btn-danger"
);

const updateTextDirection = (value) => {
  const arabicPattern = /[\u0600-\u06FF]/;
  textDirection.value = arabicPattern.test(value) ? "rtl" : "ltr";
};

const saveOrder = async () => {
  const items = await getOrderItems();
  await saveOrderPdfLocally(store, items, orderNote.value);
};

const showConfirmationModal = () => {
  openConfirmOrderModal.value = !openConfirmOrderModal.value;
};

const closeConfirmOrderModal = () => {
  openConfirmOrderModal.value = false;
};

const updateAttachmentButtonText = async () => {
  try {
    const attachments = await FileUploadRepo.listAsAttahcments(
      props.pointOfSaleCode
    );
    addAtthachmentButtonText.value =
      attachments && attachments.length > 0
        ? `${attachments.length} Attachments Included`
        : "Add Attachments";
  } catch (error) {
    console.error("Error updating attachment button text", error);
    addAtthachmentButtonText.value = "Add Attachments";
  }
};

const showUploadFileModal = () => {
  EventBus.$emit("show-upload-file-modal");
};

const getOrderItems = async () => {
  let items = await WorkingItemRepo.list(props.pointOfSaleCode);
  if (items && items.length > 0) {
    return items
      .filter((item) => item && item.product?.ProductCode && item.quantity > 0)
      .sort((a, b) =>
        a.product.ProductCode.localeCompare(b.product.ProductCode, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
  }
  return items;
};

const submitOrderCallBack = async () => {
  try {
    const items = await getOrderItems();
    const orderPdf = await getOrderPdf(store, items, orderNote.value);
    await OrderService.submitOrder(store, items, orderPdf, orderNote.value);
  } catch (error) {
    console.error("Error submitting order", error);
  }
};

const handleOrderNoteChange = debounce(async () => {
  await OrderNoteRepo.save(props.pointOfSaleCode, orderNote.value);
  store.updateHistory();
}, 1000);

const promptOrderNoteEdit = async () => {
  isEditingOrderNote.value = true;
  await nextTick();
  if (orderNoteRef.value) {
    orderNoteRef.value.focus();
  }
};

const saveCustomerNote = () => {
  handleOrderNoteChange();
  isEditingOrderNote.value = false;
};

const refreshOrderNote = async () => {
  const savedOrderNote = await OrderNoteRepo.get(props.pointOfSaleCode);
  orderNote.value = savedOrderNote ? savedOrderNote.note : "";
  await updateAttachmentButtonText();
};

onMounted(async () => {
  if (props.pointOfSaleCode) {
    await refreshOrderNote();
    updateTextDirection(orderNote.value);
    isInitialized.value = true;
  }
  EventBus.$on("working-items-cleared", refreshOrderNote);
  EventBus.$on("order-placed", refreshOrderNote);
  EventBus.$on("files-updated", updateAttachmentButtonText);
});

onBeforeUnmount(() => {
  EventBus.$off("working-items-cleared", refreshOrderNote);
  EventBus.$off("order-placed", refreshOrderNote);
  EventBus.$off("files-updated", updateAttachmentButtonText);
});

watch(() => props.pointOfSaleCode, refreshOrderNote);

watch(
  () => orderNote.value,
  (newValue) => {
    updateTextDirection(newValue);
  }
);
</script>
