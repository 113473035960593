<template>
  <div v-show="isVisible" class="popover">
    <div class="popover-header">
      <span>Note: {{ title }}</span>
      <button type="button" class="btn-close" @click="closePopover"></button>
    </div>
    <div class="popover-body">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: String,
  isVisible: Boolean,
});
const emit = defineEmits(["update:isVisible"]);
const closePopover = () => emit("update:isVisible", false);
</script>