// OrderNoteRepo.js
import db from "./dbConfig";

const TABLE_EXPIRY_TIME = {
  OrderNote: 6 * 24 * 60 * 60 * 1000,
};

export class OrderNoteRepo {
  static async get(pointOfSaleCode) {
    return await db.OrderNote.where("pointOfSaleCode")
      .equals(pointOfSaleCode)
      .first();
  }

  static async save(pointOfSaleCode, note) {
    try {
      const createdAt = Date.now();

      const existingNote = await db.OrderNote.where("pointOfSaleCode")
        .equals(pointOfSaleCode)
        .first();

      if (existingNote) {
        if (note === "" || note === null || note === undefined) {
          await db.OrderNote.delete(existingNote.id);
          return;
        }

        await db.OrderNote.update(existingNote.id, {
          note: note,
          createdAt: createdAt,
        });
      } else {
        if (note === "" || note === null || note === undefined) {
          return;
        }
        await db.OrderNote.put({
          pointOfSaleCode: pointOfSaleCode,
          note: note,
          createdAt: createdAt,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  static async clear(pointOfSaleCode) {
    try {
      await db.OrderNote.where("pointOfSaleCode")
        .equals(pointOfSaleCode)
        .delete();
      await db.OrderNote.where("createdAt")
        .below(Date.now() - TABLE_EXPIRY_TIME.OrderNote)
        .delete();
    } catch (error) {
      console.error(error);
    }
  }
}
