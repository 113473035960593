// ProductRepo.js
import db from "./dbConfig";

export class ProductRepo {
  static async getBrands() {
    const brands = await db.Brands.toArray();

    brands.sort((a, b) => a.Name.localeCompare(b.Name));

    return brands;
  }

  static async getCategories(brandId) {
    let categories;

    if (brandId) {
      const brandCategories = await db.BrandCategories.get(brandId);
      if (brandCategories) {
        categories = await db.Categories.where("Id")
          .anyOf(brandCategories.CategoryIds)
          .toArray();
      } else {
        return [];
      }
    } else {
      categories = await db.Categories.toArray();
    }

    // Sort categories alphabetically by name
    categories.sort((a, b) => a.Name.localeCompare(b.Name));

    return categories;
  }

  static async importProducts(products) {
    db.ProductsTable.clear();
    db.BrandCategories.clear();
    db.CategoryBrands.clear();
    products.sort((a, b) => {
      if (a.BrandId < b.BrandId) return -1;
      if (a.BrandId > b.BrandId) return 1;
      if (a.CategoryId < b.CategoryId) return -1;
      if (a.CategoryId > b.CategoryId) return 1;
      return 0;
    });
    await db.ProductsTable.bulkAdd(products);

    const brandCategoryMap = new Map();
    const categoryBrandMap = new Map();

    products.forEach((product) => {
      const { BrandId, CategoryId } = product;

      if (brandCategoryMap.has(BrandId)) {
        brandCategoryMap.get(BrandId).add(CategoryId);
      } else {
        brandCategoryMap.set(BrandId, new Set([CategoryId]));
      }

      if (categoryBrandMap.has(CategoryId)) {
        categoryBrandMap.get(CategoryId).add(BrandId);
      } else {
        categoryBrandMap.set(CategoryId, new Set([BrandId]));
      }
    });

    const brandCategories = Array.from(brandCategoryMap).map(
      ([BrandId, CategoryIds]) => ({
        BrandId,
        CategoryIds: Array.from(CategoryIds),
      })
    );
    await db.BrandCategories.bulkPut(brandCategories);
    const categoryBrands = Array.from(categoryBrandMap).map(
      ([CategoryId, BrandIds]) => ({
        CategoryId,
        BrandIds: Array.from(BrandIds),
      })
    );
    await db.CategoryBrands.bulkPut(categoryBrands);
    return { success: true };
  }

  static async importBrands(brands, productList) {
    const brandIdsWithProducts = new Set(
      productList.map((product) => product.BrandId)
    );
    const brandsToBeAdded = brands.filter((brand) =>
      brandIdsWithProducts.has(brand.Id)
    );
    db.Brands.clear();
    await db.Brands.bulkAdd(brandsToBeAdded);
    return { success: true };
  }

  static async importCategories(categories, productList) {
    const categoryIdsWithProducts = new Set(
      productList.map((product) => product.CategoryId)
    );
    const categoriesToBeAdded = categories.filter((category) =>
      categoryIdsWithProducts.has(category.Id)
    );
    db.Categories.clear();
    await db.Categories.bulkAdd(categoriesToBeAdded);
    return { success: true };
  }

  static async list(
    brandId,
    categoryId,
    query,
    sortDirection,
    pageNumber = 1,
    pageSize = 20
  ) {
    try {
      const offset = (pageNumber - 1) * pageSize;

      let queryBuilder = db.ProductsTable;
      if (brandId && categoryId) {
        queryBuilder = queryBuilder
          .where("[BrandId+CategoryId]")
          .equals([brandId, categoryId]);
      } else if (brandId) {
        queryBuilder = queryBuilder.where("BrandId").equals(brandId);
      } else if (categoryId) {
        queryBuilder = queryBuilder.where("CategoryId").equals(categoryId);
      }

      if (query) {
        const lowerCaseQuery = query.toLowerCase();
        queryBuilder = queryBuilder.filter((product) => {
          try {
            const productCode = (product?.ProductCode || "").toLowerCase();
            const title = (product?.Title || "").toLowerCase();
            return (
              productCode.includes(lowerCaseQuery) ||
              title.includes(lowerCaseQuery)
            );
          } catch (e) {
            console.error("Error processing product for query: ", e);
            return false;
          }
        });
      }

      let products = await queryBuilder.toArray();

      if (sortDirection && sortDirection != "none") {
        products.sort((a, b) => {
          let comparison = a.ProductCode.localeCompare(
            b.ProductCode,
            undefined,
            {
              numeric: true,
              sensitivity: "base",
            }
          );
          return sortDirection === "asc" ? comparison : -comparison;
        });
      }
      return products.slice(offset, offset + pageSize);
    } catch (error) {
      console.error("Error listing products: ", error);
      return [];
    }
  }

  static async find(productCode) {
    if (!productCode) return null;
    
    return await db.ProductsTable.where("ProductCode")
      .equals(productCode)
      .first();
  }
}
