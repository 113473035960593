<!-- ProductDisplay.vue -->

<template>
  <div>
    <span class="product-code">{{ product.ProductCode }}</span>
    <button
      class="btn btn-sm"
      @click="editProduct"
      @keydown.enter="editProduct"
    >
      <i
        title="Edit selected product"
        class="fas fa-pen-to-square"
        role="button"
      />
    </button>
  </div>
</template>
  
  <script setup>
const props = defineProps({
  product: { type: Object, required: true },
});
const emit = defineEmits(["editProduct"]);
const editProduct = () => {
  emit("editProduct");
};
</script>