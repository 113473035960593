<template>
  <div v-if="state.isReady">
    <div>
      <nav-bar></nav-bar>
      <template v-if="isLoggedIn">
        <div class="container" id="order-form-container">
          <point-of-sale></point-of-sale>
          <products-table
            :pdfMakeSetupLoaded="state.pdfMakeSetupLoaded"
          ></products-table>
        </div>
      </template>
      <template v-else>
        <h6 class="text-center mt-5">Welcome To SPCI Order Form</h6>
        <p class="text-center">
          Please
          <a :href="loginUrl">Login</a>
          to Proceed
        </p>
      </template>
    </div>
    <app-footer :is-up-to-date="isUpToDate ?? false"></app-footer>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  provide,
  nextTick,
} from "vue";
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { StoreService } from "@/services/storeService.js";
import { ConnectivityService } from "@/services/connectivityService.js";
import { logLoadTime } from "@/utils/appScript.js";
import { errorToast, infoToast, successToast } from "@/utils/toast-utils.js";
import { withRetry, debounce } from "@/utils/utils.js";
import { MESSAGES } from "@/utils/messages.js";

import NavBar from "@/views/NavBar.vue";
import PointOfSale from "@/views/PointOfSale.vue";
import ProductsTable from "@/views/ProductsTable.vue";
import AppFooter from "@/views/AppFooter.vue";

import { dataSyncService } from "./services/dataSync";
const store = useOrderFormStore();
const { isLoggedIn, userEmail, storeName } = storeToRefs(store);
const loginUrl = process.env.VUE_APP_API_loginUrl + window.location.origin;

const state = ref({
  windowWidth: window.innerWidth,
  numberOfRetries: 0,
  pdfMakeSetupLoaded: false,
  pdfMakeSetupLoadedTime: null,
  interactionStartTime: null,
  isProductDownloaded: false,
  isAuthenticatedViaApi: false,
  isReady: false,
  isOnline: false,
});

const isMobile = computed(() => state.value.windowWidth < 768);
const isUpToDate = computed(
  () => state.value.isProductDownloaded && state.value.isAuthenticatedViaApi
);

const initializeApp = async () => {
  try {
    if (isLoggedIn.value) {
      await initializeLoggedInUser();
    } else {
      await initializeNonLoggedInUser();
    }
  } catch (error) {
    handleInitializationError(error);
  } finally {
    if (isLoggedIn.value) {
      dataSyncService.triggerSync(store, state.value.isOnline);
    }
  }
};

const initializeLoggedInUser = async () => {
  state.value.isReady = true;
  state.value.interactionStartTime = new Date();
  StoreService.setAuthenticatedUserContext({
    email: userEmail.value,
    storeName: storeName.value,
  });

  if (state.value.isOnline) {
    nextTick(() => scheduleAuthCall());
  } else {
    infoToast(MESSAGES.INFO.OFFLINE_MODE);
  }
};

const initializeNonLoggedInUser = async () => {
  const authResult = await StoreService.checkUserAuthentication(store);
  if (authResult.success) {
    state.value.isAuthenticatedViaApi = true;
    state.value.isReady = true;
    state.value.interactionStartTime = new Date();
    if (isLoggedIn.value) {
      scheduleProductCall();
    }
  } else {
    state.value.interactionStartTime = new Date();
    errorToast(MESSAGES.ERROR.LOGIN_REQUIRED);
  }
};

const handleInitializationError = (error) => {
  errorToast(MESSAGES.ERROR.INIT_FAILED);
  state.value.interactionStartTime = new Date();
  console.error(MESSAGES.ERROR.INIT_FAILED, error);
};

const handleConnectivityChange = async (event) => {
  if (state.value.isOnline !== event.detail.isOnline) {
    state.value.isOnline = event.detail.isOnline;
    console.log("Connectivity status changed:", state.value.isOnline);
    if (!state.value.isOnline) {
      errorToast(MESSAGES.ERROR.LOST_CONNECTION);
    }
  }
};

const handleResize = debounce(() => {
  state.value.windowWidth = window.innerWidth;
}, 200);

const scheduleAuthCall = async () => {
  try {
    const authResult = await withRetry(
      StoreService.checkUserAuthentication,
      "scheduleAuthCall",
      store
    );
    state.value.numberOfRetries += authResult.numberOfRetries;
    if (authResult.success) {
      if (
        authResult.signInStatus &&
        userEmail.value &&
        userEmail.value !== authResult.userEmail
      ) {
        window.location.reload();
      }
      if (isLoggedIn.value) {
        state.value.isAuthenticatedViaApi = true;
        scheduleProductCall();
      } else {
        logApplicationLoadTime();
      }
    } else {
      errorToast(MESSAGES.ERROR.OFFLINE_MODE_REFRESH);
      logApplicationLoadTime();
    }
  } catch (error) {
    console.error(
      MESSAGES.ERROR.OFFLINE_MODE_REFRESH + " ScheduleAuthCall : ",
      error
    );
    errorToast(MESSAGES.ERROR.OFFLINE_MODE_REFRESH);
    logApplicationLoadTime();
  }
};

const scheduleProductCall = async () => {
  try {
    const productResult = await withRetry(
      StoreService.initializeProducts,
      "scheduleProductCall"
    );
    state.value.numberOfRetries += productResult.numberOfRetries;
    if (productResult && productResult.success && productResult.dataLoaded) {
      state.value.isProductDownloaded = true;
      successToast(MESSAGES.SUCCESS.APP_DATA_UPDATED);
    } else {
      errorToast(MESSAGES.ERROR.OFFLINE_MODE_REFRESH);
    }
  } catch (error) {
    errorToast(MESSAGES.ERROR.OFFLINE_MODE_REFRESH);
    console.error(
      MESSAGES.ERROR.OFFLINE_MODE_REFRESH + "scheduleProductCall : ",
      error
    );
  } finally {
    logApplicationLoadTime();
  }
};

const logApplicationLoadTime = () => {
  logLoadTime(
    isUpToDate,
    state.value.numberOfRetries,
    state.value.isProductDownloaded,
    state.value.isAuthenticatedViaApi,
    state.value.interactionStartTime,
    state.value.pdfMakeSetupLoadedTime
  );
};

const onSetupCompleted = () => {
  if (!state.value.pdfMakeSetupLoaded) {
    state.value.pdfMakeSetupLoadedTime = new Date();
  }
  state.value.pdfMakeSetupLoaded = true;
};

const onSetupError = () => {
  errorToast(
    "Failed to load files for pdf generation. Please try again by reloading the page and making sure you have internet connection."
  );
  state.value.pdfMakeSetupLoaded = false;
};

const eventMount = () => {
  window.addEventListener("resize", handleResize);
  window.addEventListener("pdfmake-setup-completed", onSetupCompleted);
  window.addEventListener("pdfmake-setup-error", onSetupError);
  const event = new Event("app-loaded");
  document.dispatchEvent(event);
  document.addEventListener(
    "connectivity-status-changed",
    handleConnectivityChange
  );
};

const eventUnMount = () => {
  window.removeEventListener("pdfmake-setup-completed", onSetupCompleted);
  window.removeEventListener("pdfmake-setup-error", onSetupError);
  document.removeEventListener(
    "connectivity-status-changed",
    handleConnectivityChange
  );
  window.removeEventListener("resize", handleResize);
};

onMounted(async () => {
  await store.getUser();
  state.value.isOnline = await ConnectivityService.isOnline();
  await initializeApp();
  eventMount();
  ConnectivityService.startMonitoring(store);
});

onBeforeUnmount(() => {
  eventUnMount();
  ConnectivityService.stopMonitoring();
});

provide(
  "onlineStatus",
  computed(() => state.value.isOnline)
);
provide("isMobile", isMobile);
</script>