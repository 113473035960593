// utils/productUtils.js
import { ProductRepo } from "@/services/DbAccess/ProductRepo";
import { generateUUID } from "./utils";
export const createNewItem = (pointOfSaleCode, product) => ({
  searchTerm: product && product.ProductCode ? product.ProductCode : "",
  pointOfSaleCode: pointOfSaleCode,
  quantity: 1,
  uId: generateUUID(),
  notes: "",
  product: product ? product : {},
  searchResults: [],
  editing: product ? false : true,
});

const isProductInItemList = (product, itemList) => {
  if (!product || !Array.isArray(itemList) || itemList.length === 0) {
    return false;
  }

  return itemList.some(
    (item) => item?.product?.ProductCode === product.ProductCode
  );
};

const isProductBeingEdited = (product, itemList) => {
  if (!product || !Array.isArray(itemList) || itemList.length === 0) {
    return false;
  }

  return itemList.some(
    (item) =>
      item?.editing && item?.product?.ProductCode === product.ProductCode
  );
};

const filterSearchResults = (results, itemList) => {
  return results
    .filter((result) => {
      return (
        isProductBeingEdited(result, itemList) ||
        !isProductInItemList(result, itemList)
      );
    })
    .slice(0, 10);
};

export const searchAndFilterProducts = async (searchTerm, itemList) => {
  if (!searchTerm?.trim()) {
    return { items: [] };
  }

  try {
    const allResults = await ProductRepo.list(
      null,
      null,
      searchTerm,
      "asc",
      1,
      10
    );
    const filteredResults = filterSearchResults(allResults, itemList);

    return { items: filteredResults };
  } catch (error) {
    console.error("Error searching and filtering products:", error);
    return { error: "Error searching and filtering products" };
  }
};
