import { logoBase64 } from "@/assets/logoBase64.js";

const FONT_SIZES = {
  content: 6,
  tableHeader: 9,
  tableBody: 8,
};

const COLORS = {
  lightGray: "#e0e0e0",
  darkGray: "#5A5A5A",
};

export const getOrderPdf = (store, items, orderNote) =>
  generatePdf(store, items, orderNote);

export const saveOrderPdfLocally = (store, items, orderNote) =>
  generatePdf(store, items, orderNote, true);

const generatePdf = async (store, items, orderNote, isDownload = false) => {
  const dateTime = new Date();
  const dateTimeAsString = dateTime.toGMTString();
  const pointOfSale = await store.getSelectedPointOfSale();

  const docDefinition = await getDocDefinition(
    store,
    items,
    dateTimeAsString,
    pointOfSale,
    orderNote
  );

  const generatedPdf = window.pdfMake.createPdf(docDefinition);

  if (isDownload) {
    generatedPdf.download(
      `Order - ${pointOfSale?.code} - ${dateTimeAsString}.pdf`
    );
    return;
  }

  return new Promise((resolve) => {
    generatedPdf.getBase64((data) => {
      resolve(data);
    });
  });
};

const processText = (text) => {
  try {
    const lines = text.split("\n");
    return lines
      .map((line) => {
        const words = line.split(" ");
        const arabic = words.filter((e) => /[\u0600-\u06ff]/.test(e));
        const english = words.filter((e) => !/[\u0600-\u06ff]/.test(e));
        return `${english.join(" ")}  ${arabic.reverse().join("  ")}`;
      })
      .join("\n");
  } catch (e) {
    console.error("processText", e);
    return text;
  }
};

const tableLayout = {
  fillColor: (rowIndex) => (rowIndex === 0 ? "#f8f9fa" : null),
  vLineWidth: () => 0.5,
  hLineColor: () => COLORS.lightGray,
  vLineColor: () => COLORS.lightGray,
  hLineWidth: (i, node) =>
    i === 0 || i === node.table.body.length - 1 ? 0.5 : 0.5,
  padding: () => 5,
  paddingTop: () => 6,
  paddingBottom: () => 6,
};

const buildHeader = () => [
  {
    columns: [
      {
        margin: [20, 15, 20, 0],
        image: logoBase64,
        width: 50,
      },
      {
        text: "SPCI Order Form",
        alignment: "right",
        fontSize: 7,
        color: COLORS.darkGray,
        margin: [0, 20, 20, 0],
      },
    ],
  },
  { text: " ", margin: [20, 15, 20, 40] },
];

const buildFooter = (currentPage, pageCount) => ({
  stack: [
    {
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 0,
          x2: 700,
          y2: 0,
          lineWidth: 0.5,
          lineColor: COLORS.lightGray,
        },
      ],
    },
    {
      margin: [20, 15, 20, 0],
      fontSize: 7,
      color: COLORS.darkGray,
      columns: [
        "SPCI Order Form",
        {
          text: `Page ${currentPage} of ${pageCount}`,
          alignment: "right",
        },
      ],
    },
  ],
});

const generateTableBody = (items) => {
  const headers = ["", "Code", "Product", "Quantity", "Notes"].map(
    (header) => ({
      text: header,
      bold: true,
      fillColor: COLORS.lightGray,
      margin: [0, 2, 0, 2],
      fontSize: FONT_SIZES.tableHeader,
    })
  );

  if (!items || !Array.isArray(items) || items.length === 0) {
    return [headers];
  }

  const rows = items.map((item, index) => [
    { text: index + 1, fontSize: FONT_SIZES.tableBody },
    { text: item.product.ProductCode, fontSize: FONT_SIZES.tableBody },
    { text: item.product.Title, fontSize: FONT_SIZES.tableBody },
    { text: item.quantity.toString(), fontSize: FONT_SIZES.tableBody },
    {
      text: containsArabicText(item.notes)
        ? processText(item.notes)
        : item.notes,
      fontSize: FONT_SIZES.content,
      font: containsArabicText(item.notes) ? "Amiri" : "Roboto",
      alignment: containsArabicText(item.notes) ? "right" : "left",
    },
  ]);

  return [headers, ...rows];
};

const formatCustomerName = (name) => {
  if (typeof name !== "string" || name.trim() === "") {
    console.error("Invalid name provided to formatCustomerName:", name);
    return name;
  }

  return name
    .split(" ")
    .map((word) =>
      word.trim()
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word
    )
    .join(" ");
};

const containsArabicText = (value) => /[\u0600-\u06FF]/.test(value);

const getDocDefinition = async (
  store,
  items,
  dateTimeAsString,
  pointOfSale,
  orderNote
) => {
  const user = await store.getUser();
  if (!user) return;

  const note = orderNote || "";
  const valueContainsArabicText = containsArabicText(note);

  return {
    header: buildHeader,
    footer: buildFooter,
    content: [
      {
        text: "Order Form",
        fontSize: 15,
        bold: true,
        alignment: "center",
        margin: [0, 30, 0, 30],
      },
      {
        columns: [
          {
            width: "65%",
            margin: [0, 0, 0, 0],
            stack: [
              createInfoRow("Customer Code:", pointOfSale.code.toUpperCase()),
              createInfoRow(
                "Customer Name:",
                formatCustomerName(pointOfSale.customerName)
              ),
              createInfoRow("Billing Group:", pointOfSale.billingGroup),
              {
                text: "Order Note:",
                fontSize: FONT_SIZES.tableBody,
                bold: true,
                margin: [0, 20, 0, 0],
              },
              {
                text: valueContainsArabicText ? processText(note) : note,
                fontSize: FONT_SIZES.tableBody,
                font: valueContainsArabicText ? "Amiri" : "Roboto",
                alignment: valueContainsArabicText ? "right" : "left",
                margin: [0, 2, 0, 0],
              },
            ],
          },
          {
            width: "35%",
            margin: [0, 0, 0, 0],
            stack: [
              createInfoRow("Order Date:", dateTimeAsString),
              createInfoRow("Sales Person Name:", user.name),
              createInfoRow("Sales Person Email:", user.email),
            ],
          },
        ],
      },
      {
        table: {
          widths: [20, 90, 130, 50, "*"],
          body: generateTableBody(items),
        },
        layout: tableLayout,
        margin: [0, 15, 0, 15],
      },
    ],
  };
};

const createInfoRow = (label, value) => ({
  columns: [
    { text: label, fontSize: FONT_SIZES.tableBody, bold: true, width: "auto" },
    {
      text: value,
      fontSize: FONT_SIZES.tableBody,
      width: "*",
      margin: [5, 0, 0, 0],
    },
  ],
  margin: [0, 5, 0, 0],
});
