const maxRetries = 5;
const retryDelay = 5000;
const fetchTimeout = 4000;
let fontCache = {};

const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

const fetchFont = async (url, fontName) => {
  if (fontCache[fontName]) return fontCache[fontName];

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), fetchTimeout);

  try {
    const response = await fetch(url, {
      signal: controller.signal,
      cache: "default",
    });
    clearTimeout(timeoutId);

    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
    }

    const buffer = await response.arrayBuffer();
    const base64Font = arrayBufferToBase64(buffer);
    fontCache[fontName] = base64Font;
    return base64Font;
  } catch (error) {
    console.error(`Failed to fetch font ${fontName} from ${url}:`, error);
    throw error;
  }
};

const setupPdfMake = async () => {
  if (!window.pdfMake) throw new Error("pdfMake is not available");

  window.pdfMake.vfs = window.pdfMake.vfs || {};

  const fontUrls = {
    "Roboto-Regular.ttf":
      "https://synergyprosales.blob.core.windows.net/public/fonts/Roboto-Regular.ttf",
    "Roboto-Medium.ttf":
      "https://synergyprosales.blob.core.windows.net/public/fonts/Roboto-Medium.ttf",
    "Amiri-Regular.ttf":
      "https://synergyprosales.blob.core.windows.net/public/fonts/Amiri-Regular.ttf",
    "Amiri-Bold.ttf":
      "https://synergyprosales.blob.core.windows.net/public/fonts/Amiri-Bold.ttf",
  };

  try {
    const fontPromises = Object.entries(fontUrls).map(
      async ([fontName, url]) => {
        window.pdfMake.vfs[fontName] = await fetchFont(url, fontName);
      }
    );

    await Promise.all(fontPromises);

    window.pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
      Amiri: {
        normal: "Amiri-Regular.ttf",
        bold: "Amiri-Bold.ttf",
      },
    };
  } catch (error) {
    console.error("Failed to set pdfMake fonts:", error);
    throw new Error(`Failed to set pdfMake fonts: ${error.message}`);
  }
};

const setupPdfMakeWithRetries = async (retries = 0) => {
  try {
    await setupPdfMake();
    const event = new Event("pdfmake-setup-completed");
    window.dispatchEvent(event);
    setTimeout(() => window.dispatchEvent(event), 10000);
    setTimeout(() => window.dispatchEvent(event), 20000);
    setTimeout(() => window.dispatchEvent(event), 30000);
    fontCache = {};
  } catch (error) {
    console.error("Error during PDFMake setup:", error);
    if (retries < maxRetries) {
      console.warn(
        `Retrying pdfMake setup (${retries + 1}/${maxRetries}) due to error: ${
          error.message
        }`
      );
      setTimeout(() => setupPdfMakeWithRetries(retries + 1), retryDelay);
    } else {
      console.error("Error during PDFMake setup after retries:", error);
      const errorEvent = new CustomEvent("pdfmake-setup-error", {
        detail: error,
      });
      window.dispatchEvent(errorEvent);
    }
  }
};

const loadPdfMakeScriptWithRetries = (retries = 0) => {
  try {
    const script = document.createElement("script");

    try {
      script.src =
        "https://synergyprosales.blob.core.windows.net/public/js/pdfmake.min.js.gz";
      script.async = true;
      script.crossOrigin = "anonymous";
      script.referrerPolicy = "no-referrer";
    } catch (error) {
      console.error("Error setting script properties:", error);
      throw error; // Let the outer catch block handle the retry logic
    }

    script.onload = () => {
      try {
        setupPdfMakeWithRetries();
      } catch (error) {
        console.error("Error in handlePdfMakeLoaded:", error);
        const errorEvent = new CustomEvent("pdfmake-setup-error", {
          detail: error,
        });
        window.dispatchEvent(errorEvent);
      }
    };

    script.onerror = () => {
      if (retries < maxRetries) {
        console.warn(
          `Retrying pdfMake script load (${retries + 1}/${maxRetries})`
        );
        setTimeout(() => loadPdfMakeScriptWithRetries(retries + 1), retryDelay);
      } else {
        console.error("Failed to load pdfMake script after retries");
        const errorEvent = new CustomEvent("pdfmake-setup-error", {
          detail: new Error("Failed to load pdfMake script"),
        });
        window.dispatchEvent(errorEvent);
      }
    };

    document.body.appendChild(script);
  } catch (error) {
    if (retries < maxRetries) {
      console.warn(
        `Retrying pdfMake script load (${retries + 1}/${maxRetries})`
      );
      setTimeout(() => loadPdfMakeScriptWithRetries(retries + 1), retryDelay);
    } else {
      console.error("Failed to load pdfMake script after retries");
      const errorEvent = new CustomEvent("pdfmake-setup-error");
      window.dispatchEvent(errorEvent);
    }
  }
};

document.addEventListener("app-loaded", function () {
  if (window.pdfMake) {
    setupPdfMakeWithRetries();
  } else {
    loadPdfMakeScriptWithRetries();
  }
});
