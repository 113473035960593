import EventBus from "@/services/eventBus";
import db from "./dbConfig";

const TABLE_EXPIRY_TIME = {
  PointsOfSale: 30 * 24 * 60 * 60 * 1000,
};

export class PointsOfSaleRepo {
  static async import(pointsOfSales) {
    try {
      const { bulkPut, bulkAdd, entriesToDelete } =
        await this.#prepareImportData(pointsOfSales);

      if (
        bulkPut.length > 0 ||
        bulkAdd.length > 0 ||
        entriesToDelete.length > 0
      ) {
        await this.#performBulkOperations(bulkPut, bulkAdd, entriesToDelete);
      }

      EventBus.$emit("points-of-sale-imported");
      return { success: true };
    } catch (error) {
      console.error("saveImportedPointOfSales error: ", error);
      return { success: false, error };
    }
  }

  static async save(code, customerName, storeId) {
    try {
      if (!this.#validateSaveParams(code, storeId)) {
        return {
          success: false,
          message: "Code and storeId are required",
        };
      }

      const savePosResult = await this.#savePointOfSale(
        code,
        customerName,
        storeId
      );
      if (!savePosResult.success) return savePosResult;

      await Promise.all([
        this.#updateSelectedPointOfSale(code, storeId, customerName),
        this.#savePointOfSaleHistory(code, customerName, storeId),
      ]);

      return savePosResult;
    } catch (err) {
      console.error("Error saving point of sale", err);
      return { success: false, message: "Error saving point of sale" };
    }
  }

  static async listHistory(query, limit = 20, storeId) {
    try {
      if (!storeId) return null;

      await this.#deleteExpiredEntries();
      const { workingItemsSet, orderNoteSet, attachmentSet } =
        await this.#getRelatedSets();

      const results = await this.#queryHistoryResults(
        storeId,
        query,
        workingItemsSet,
        orderNoteSet,
        attachmentSet
      );
      return this.#sortAndLimitResults(results, limit);
    } catch (error) {
      console.error("Error listing points of sale history: ", error);
      return [];
    }
  }

  static async get(storeId) {
    if (!storeId) return null;

    const pos = await db.SelectePointOfSale.where("storeId")
      .equals(storeId)
      .first();

    if (!pos) return { success: false };

    const posintOfsale = await db.PointsOfSale.where("[code+storeId]")
      .equals([pos.code, storeId])
      .first();

    if (!posintOfsale) {
      const saveResult = await this.save(pos.code, pos.customerName, storeId);
      if (!saveResult?.success) {
        return { success: false };
      }
    }
    return {
      success: true,
      code: posintOfsale?.code || pos.code,
      customerName: posintOfsale?.customerName || pos.customerName,
      billingGroup: posintOfsale?.billingGroup,
      imported: posintOfsale?.imported,
    };
  }

  static async clear() {
    await db.SelectePointOfSale.clear();
  }

  static async list(query, limit = 10, storeId) {
    try {
      if (!storeId) return null;

      const results = await this.#queryPointsOfSale(storeId, query);
      return this.#sortAndLimitResults(results, limit);
    } catch (error) {
      console.error("Error listing points of sale: ", error);
      return [];
    }
  }

  static async findFromHistory(code, storeId) {
    try {
      if (!code || !storeId) return null;

      return await db.WorkingHistoryPointOfSale.where("[code+storeId]")
        .equals([code.toUpperCase(), storeId])
        .first();
    } catch (error) {
      console.error("Error finding points of sale history: ", error);
      return null;
    }
  }

  static async find(code, storeId) {
    if (!code || !storeId) return null;

    return await db.PointsOfSale.where("[code+storeId]")
      .equals([code.toUpperCase(), storeId])
      .first();
  }
  static async updatePointOfSalesHistory(code, storeId) {
    try {
      if (!code || !storeId) return;

      const existingEntry = await db.WorkingHistoryPointOfSale.where(
        "[code+storeId]"
      )
        .equals([code, storeId])
        .first();

      if (existingEntry) {
        await db.WorkingHistoryPointOfSale.update(existingEntry.id, {
          createdAt: Date.now(),
        });
      }
    } catch (error) {
      console.log(
        "Error updating point of sale history last modified: ",
        error
      );
    }
  }
  // Private methods

  static async #prepareImportData(pointsOfSales) {
    const existingEntries = await db.PointsOfSale.toArray();
    const bulkPut = [];
    const bulkAdd = [];
    const incomingCodes = new Set();

    const existingEntriesMap = new Map(
      existingEntries.map((ps) => [ps.code?.toLowerCase() ?? "", ps])
    );

    for (const incomingPs of pointsOfSales) {
      if (!incomingPs?.code?.trim() || !incomingPs?.customerName?.trim())
        continue;

      const lowercaseCode = incomingPs.code.toLowerCase();
      const uppercaseCode = incomingPs.code.toUpperCase();
      incomingCodes.add(lowercaseCode);

      const existingEntry = existingEntriesMap.get(lowercaseCode);

      if (existingEntry) {
        if (this.#shouldUpdateExistingEntry(existingEntry, incomingPs)) {
          bulkPut.push(
            this.#createUpdatedEntry(existingEntry, incomingPs, uppercaseCode)
          );
        }
      } else {
        bulkAdd.push(this.#createNewEntry(incomingPs, uppercaseCode));
      }
    }

    const entriesToDelete = existingEntries
      .filter(
        (pos) =>
          !incomingCodes.has(pos.code?.toLowerCase() ?? "") && pos.imported
      )
      .map((pos) => pos.code);

    return { bulkPut, bulkAdd, entriesToDelete };
  }
  static #shouldUpdateExistingEntry(existingEntry, incomingPs) {
    const normalizeString = (str) => str?.trim().toUpperCase() ?? "";
    return (
      normalizeString(existingEntry.customerName) !==
        normalizeString(incomingPs.customerName) ||
      normalizeString(existingEntry.billingGroup) !==
        normalizeString(incomingPs.billingGroup)
    );
  }

  static #createUpdatedEntry(existingEntry, incomingPs, uppercaseCode) {
    return {
      ...existingEntry,
      code: uppercaseCode,
      customerName: incomingPs.customerName,
      billingGroup: incomingPs.billingGroup,
      imported: true,
      createdAt: Date.now(),
    };
  }

  static #createNewEntry(incomingPs, uppercaseCode) {
    return {
      ...incomingPs,
      code: uppercaseCode,
      customerName: incomingPs?.customerName ?? "",
      billingGroup: incomingPs?.billingGroup ?? "",
      imported: true,
      createdAt: Date.now(),
    };
  }
  static async #performBulkOperations(bulkPut, bulkAdd, entriesToDelete) {
    await db.transaction("rw", db.PointsOfSale, async () => {
      if (bulkPut.length > 0) await db.PointsOfSale.bulkPut(bulkPut);
      if (bulkAdd.length > 0) await db.PointsOfSale.bulkAdd(bulkAdd);
      if (entriesToDelete.length > 0)
        await db.PointsOfSale.bulkDelete(entriesToDelete);
    });
  }

  static #validateSaveParams(code, storeId) {
    return code && storeId;
  }

  static async #savePointOfSale(code, customerName, storeId) {
    try {
      if (!code || !storeId)
        return { success: false, message: "Code and StoreId are required" };

      const normalizedCode = code.toUpperCase();
      const existingEntry = await db.PointsOfSale.where("[code+storeId]")
        .equals([normalizedCode, storeId])
        .first();

      await db.PointsOfSale.put({
        code: existingEntry?.code ?? normalizedCode,
        customerName: customerName,
        storeId: storeId,
        createdAt: Date.now(),
        billingGroup: existingEntry?.billingGroup ?? "",
        imported: existingEntry?.imported ?? false,
      });

      return {
        success: true,
        code: normalizedCode,
        customerName: customerName,
        billingGroup: existingEntry?.billingGroup,
        storeId: storeId,
        imported: existingEntry?.imported ?? false,
      };
    } catch (err) {
      console.error("#savePointOfSale error :", err);
      return {
        success: false,
        message: "Error saving point of sale. catch Error ",
      };
    }
  }

  static async #updateSelectedPointOfSale(code, storeId, customerName) {
    await db.SelectePointOfSale.clear();
    await db.SelectePointOfSale.put({
      code: code.toUpperCase(),
      storeId: storeId,
      customerName: customerName,
    });
  }

  static async #savePointOfSaleHistory(code, customerName, storeId) {
    try {
      if (!code || !storeId || !customerName) {
        return {
          success: false,
          message: "Code, customerName and StoreId are required",
        };
      }

      const normalizedCode = (code || "").toUpperCase();
      const existingEntry = await db.WorkingHistoryPointOfSale.where(
        "[code+storeId]"
      )
        .equals([normalizedCode, storeId])
        .first();

      if (existingEntry) {
        await db.WorkingHistoryPointOfSale.update(existingEntry.id, {
          customerName: customerName,
        });
      } else {
        await db.WorkingHistoryPointOfSale.add({
          code: normalizedCode,
          customerName: customerName,
          storeId: storeId,
          createdAt: Date.now(),
        });
      }

      return { success: true };
    } catch (err) {
      console.error("#savePointOfSaleHistory", err);
      return { success: false, message: "Error saving point of sale history" };
    }
  }

  static async #deleteExpiredEntries() {
    await db.WorkingHistoryPointOfSale.where("createdAt")
      .below(Date.now() - TABLE_EXPIRY_TIME.PointsOfSale)
      .delete();
  }

  static async #getRelatedSets() {
    const [workingItems, orderNotes, attachments] = await Promise.all([
      db.WorkingItems.toArray(),
      db.OrderNote.filter(
        (orderNote) => orderNote.note.trim() !== ""
      ).toArray(),
      db.Files.toArray(),
    ]);

    return {
      workingItemsSet: new Set(
        workingItems.map((item) => item.pointOfSaleCode)
      ),
      orderNoteSet: new Set(orderNotes.map((item) => item.pointOfSaleCode)),
      attachmentSet: new Set(attachments.map((item) => item.pointOfSaleCode)),
    };
  }

  static async #queryHistoryResults(
    storeId,
    query,
    workingItemsSet,
    orderNoteSet,
    attachmentSet
  ) {
    const caseInsensitiveQuery = query?.toLowerCase() ?? "";

    return await db.WorkingHistoryPointOfSale.where("storeId")
      .equals(storeId)
      .filter((pos) => {
        const posCode = pos?.code?.toLowerCase() ?? "";
        const customerName = pos?.customerName?.toLowerCase() ?? "";

        return (
          (posCode.includes(caseInsensitiveQuery) ||
            customerName.includes(caseInsensitiveQuery)) &&
          (orderNoteSet.has(pos?.code) ||
            workingItemsSet.has(pos?.code) ||
            attachmentSet.has(pos?.code))
        );
      })
      .toArray();
  }

  static async #queryPointsOfSale(storeId, query) {
    const caseInsensitiveQuery = (query || "").toLowerCase();

    return await db.PointsOfSale.where("storeId")
      .equals(storeId)
      .filter((pos) => {
        const posCode = (pos?.code || "").toLowerCase();
        const customerName = (pos?.customerName || "").toLowerCase();
        return (
          posCode.includes(caseInsensitiveQuery) ||
          customerName.includes(caseInsensitiveQuery)
        );
      })
      .toArray();
  }

  static #sortAndLimitResults(results, limit) {
    if (!results || results.length === 0) return [];
    return results.sort((a, b) => b.createdAt - a.createdAt).slice(0, limit);
  }
}
