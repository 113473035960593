<template>
  <div>
    <div>
      <InputField
        v-model="localQuantity"
        @enterPressed="focusOnNextItem(1)"
        @inputChanged="updateQuantityFiled"
        ref="inputFieldRef"
        class="desktop-input-width"
        name="quantity-input"
        :value="item.quantity"
        :min-value="1"
        autocomplete="off"
      />
    </div>
    <div>
      <ProductSearch
        v-if="item.editing"
        :results="item.searchResults"
        :initiateProduct="item.product"
        :searchQuery="item?.product?.ProductCode"
        @inputChanged="updateSearchTerm"
        ref="productSearchRef"
        @productSelected="selectedProduct"
      />
      <ProductDisplay
        v-else-if="item.product"
        :product="item.product"
        @editProduct="initiateProductEdit"
      />
    </div>

    <div>
      {{ item?.product?.Title }}
    </div>

    <div>
      <TextArea
        title="Add note for this item"
        v-model="localNotes"
        :value="item.notes"
        class="form-control"
        @inputChanged="updateNotesField"
        placeholder="Notes"
        name="note-input"
        ref="textAreaRef"
      />
    </div>

    <div>
      <ImageComponet
        :baseUrl="item?.product?.MainPhotoUrl"
        :width="Number(45)"
        :height="Number(45)"
        :tab-index="0"
        :title="item?.product?.Title"
        :allow-pop-up-on-click="true"
      ></ImageComponet>
    </div>
    <div>
      <button
        title="Remove this item"
        @click="removeRow"
        tabindex="0"
        @keydown.enter.prevent="removeRow"
        class="btn btn-sm"
        role="button"
      >
        <i
          title="Remove this item"
          class="icon-trash fas fa-trash"
          role="button"
        />
      </button>
    </div>
  </div>
</template>
  <script setup>
import { ref, watch, nextTick } from "vue";
import ImageComponet from "../../General/ImageComponent.vue";
import ProductSearch from "./ProductSearch.vue";
import ProductDisplay from "./ProductDisplay.vue";
import InputField from "../../General/InputField.vue";
import TextArea from "../../General/TextArea.vue";

const props = defineProps({
  item: { type: Object, required: true },
  index: { type: String, required: true },
});

const emit = defineEmits([
  "edit-product",
  "product-selected",
  "input-changed",
  "remove-row",
]);

const localQuantity = ref(props.item.quantity);
const localNotes = ref(props.item.notes);
const productSearchRef = ref(null);
const inputFieldRef = ref(null);
const textAreaRef = ref(null);

watch(
  () => props.item.quantity,
  (newVal) => {
    localQuantity.value = newVal;
  }
);
watch(
  () => props.item.notes,
  (newVal) => {
    localNotes.value = newVal;
  }
);

const removeRow = () => {
  emit("remove-row", props.index);
};

const initiateProductEdit = () => {
  emit("edit-product", props.index);
};

const selectedProduct = (product) => {
  emit("product-selected", { product, rowIndex: props.index });

  focusOnNextItem(0);
};

const updateSearchTerm = (value) => {
  emit("input-changed", { value, fieldType: "searchTerm", index: props.index });
};

const updateQuantityFiled = (value) => {
  emit("input-changed", { value, fieldType: "quantity", index: props.index });
};

const updateNotesField = (value) => {
  emit("input-changed", { value, fieldType: "notes", index: props.index });
};

const focusOnNextItem = (idx) =>
  nextTick(() => {
    [inputFieldRef, textAreaRef][idx].value.focusInput();
  });
</script>
