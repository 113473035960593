<template>
  <div class="expand-view">
    <span v-if="hasProduct" @click="toggleView">
      <i class="toggle-view-btn fas fa-chevron-up" role="button" />
    </span>

    <div v-if="props?.item?.product?.ProductCode">
      <ImageComponent
        :baseUrl="props.item.product?.MainPhotoUrl"
        :title="props.item.product?.Title"
        :width="Number(45)"
        :height="Number(45)"
        :round="String('max')"
        :allow-pop-up-on-click="true"
      ></ImageComponent>
    </div>

    <div>
      <div v-if="hasProduct" class="quantity-wrapper">
        <InputField
          v-model="localQuantity"
          @inputChanged="updateQuantityFiled"
          ref="inputFieldRef"
          class="input-width"
          name="quantity-input"
          :value="item.quantity"
          autocomplete="off"
          :min-value="1"
        />
      </div>

      <div>
        <ProductSearch
          v-if="item.editing"
          :results="item.searchResults"
          :initiateProduct="item.product"
          :searchQuery="item?.product?.ProductCode"
          @inputChanged="updateSearchTerm"
          ref="productSearchRef"
          @productSelected="selectedProduct"
        />
        <ProductDisplay
          v-else-if="item.product"
          :product="item.product"
          @editProduct="initiateProductEdit"
        />
        <div v-if="hasProduct">
          <span class="product-title">{{ item.product.Title }}</span>
        </div>
      </div>

      <div v-if="hasProduct">
        <TextArea
          v-if="isEditingNote"
          v-model="localNotes"
          @inputChanged="updateNotesField"
          placeholder="Notes"
          :value="localNotes"
          ref="textAreaRef"
        />
        <div v-else>
          {{ localNotes }}
        </div>
        <button
          class="btn btn-sm"
          @click="handleNoteButtonClick"
          role="button"
          tabindex="0"
        >
          <span v-if="isEditingNote"
            ><i class="fas fa-save text-success"
          /></span>
          <span v-else><i class="fas fa-comment" /></span>
        </button>
      </div>
    </div>

    <div style="text-align: right">
      <button
        title="Remove this item"
        class="btn btn-sm"
        @click="removeRow"
        role="button"
        @keydown.enter.prevent="removeRow"
      >
        <i class="icon-trash fas fa-trash" />
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, computed, nextTick } from "vue";
import ImageComponent from "@/components/General/ImageComponent.vue";
import ProductSearch from "./ProductSearch.vue";
import ProductDisplay from "./ProductDisplay.vue";
import InputField from "@/components/General/InputField.vue";
import TextArea from "@/components/General/TextArea.vue";

const props = defineProps({
  item: { type: Object, required: true },
  index: { type: String, required: true },
});

const emit = defineEmits([
  "edit-product",
  "product-selected",
  "input-changed",
  "remove-row",
  "toggle-view",
]);

const localQuantity = ref(props.item.quantity);
const localNotes = ref(props.item.notes);
const productSearchRef = ref(null);
const textAreaRef = ref(null);
const isEditingNote = ref(false);

const hasProduct = computed(
  () => props.item.product && props.item.product.ProductCode
);

watch(
  () => props.item.quantity,
  (newVal) => {
    localQuantity.value = newVal;
  }
);

watch(
  () => props.item.notes,
  (newVal) => {
    localNotes.value = newVal;
  }
);

const toggleView = () => {
  emit("toggle-view");
};

const handleNoteButtonClick = async () => {
  isEditingNote.value = !isEditingNote.value;
  await nextTick();
  if (textAreaRef.value) {
    textAreaRef.value.focusInput();
  }
};

const removeRow = () => {
  emit("remove-row", props.index);
};

const initiateProductEdit = async () => {
  emit("edit-product", props.index);
  await nextTick();
};

const selectedProduct = async (product) => {
  emit("product-selected", { product, rowIndex: props.index });
};

const updateSearchTerm = (value) => {
  emit("input-changed", { value, fieldType: "searchTerm", index: props.index });
};

const updateQuantityFiled = (value) => {
  emit("input-changed", { value, fieldType: "quantity", index: props.index });
};

const updateNotesField = (value) => {
  emit("input-changed", { value, fieldType: "notes", index: props.index });
};
</script> 