<template>
  <div v-if="isMobile" class="small-screen-container">
    <MobileRowCollapsed
      v-if="isCollabsable"
      :item="props.item"
      @toggle-view="toggleView"
      @remove-row="removeRow"
    ></MobileRowCollapsed>
    <MobileRow
      v-else
      :item="item"
      :index="index"
      @toggle-view="toggleView"
      @input-changed="onInputChanged"
      @remove-row="removeRow"
      @edit-product="onEditProduct"
      @product-selected="selectedProduct"
    />
  </div>
  <DesktopRow
    v-else
    class="larg-screen-container"
    :item="item"
    :index="index"
    @input-changed="onInputChanged"
    @remove-row="removeRow"
    @edit-product="onEditProduct"
    @product-selected="selectedProduct"
  />
</template>

<script setup>
import { ref, computed, onMounted, inject } from "vue";

import MobileRowCollapsed from "./MobileRowCollapsed.vue";
import MobileRow from "./MobileRowExpanded.vue";
import DesktopRow from "./DesktopRow.vue";
import { debounce } from "@/utils/utils.js";
const isMobile = inject("isMobile");

const props = defineProps({
  item: { type: Object, required: true },
  index: { type: String, required: true },
});

const emit = defineEmits([
  "edit-product",
  "product-selected",
  "input-changed",
  "remove-row",
]);

const collapsed = ref(true);

const isCollabsable = computed(
  () => isMobile.value && hasProduct.value && collapsed.value
);

const hasProduct = computed(
  () => props.item.product && props.item.product.ProductCode
);

onMounted(() => {
  if (isMobile.value) {
    collapsed.value = true;
  }
});

const toggleView = () => {
  collapsed.value = !collapsed.value;
};

const onEditProduct = (index) => {
  emit("edit-product", index);
};

const removeRow = () => {
  emit("remove-row", props.index);
};

const selectedProduct = (payload) => {
  emit("product-selected", payload);
};

const onInputChanged = debounce((payload) => {
  emit("input-changed", payload);
}, 100);
</script>