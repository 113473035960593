import { deflate } from "pako/lib/deflate";
import { inflate } from "pako/lib/inflate";

const uint8ArrayToBase64 = (arr) => {
  return btoa(String.fromCharCode.apply(null, arr));
};

const base64ToUint8Array = (base64) => {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const compressData = (data) => {
  try {
    if (!data || data.length === 0) {
      return null;
    }
    const jsonString = JSON.stringify(data);
    const compressed = deflate(jsonString);
    return uint8ArrayToBase64(compressed);
  } catch (error) {
    console.error("Error compressing data:", error);
    return null;
  }
};

export const decompressData = (compressedData) => {
  try {
    const decoded = base64ToUint8Array(compressedData);
    const decompressed = inflate(decoded, { to: "string" });
    return JSON.parse(decompressed);
  } catch (error) {
    console.error("Error decompressing data:", error);
    return null;
  }
};
