<template>
  <div :class="modalClasses" tabindex="-1">
    <div
      class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Upload Attachments</h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <p class="text-muted">
            Manage your file attachments. Upload new files or delete existing
            ones.
          </p>
          <div class="item-list">
            <div class="item-header">
              <div
                v-for="header in fileHeaders"
                :key="header"
                class="header-item"
              >
                {{ header }}
              </div>
            </div>
            <template v-if="dataIsLoading">
              <div class="item-row loading">
                <div class="loading-message">
                  Loading...
                  <i class="fa fa-arrows-rotate rotate-animation"></i>
                </div>
              </div>
            </template>
            <template v-else-if="files.length === 0">
              <div class="item-row no-data">
                <div class="no-data-message">No files found 🤔</div>
              </div>
            </template>
            <template v-else>
              <div v-for="file in files" :key="file.fileName" class="item-row">
                <div class="list-item">
                  <span class="item-label">File Name:</span>
                  <span class="item-value">{{ file.fileName }}</span>
                </div>
                <div class="list-item">
                  <span class="item-label">Status:</span>
                  <span class="item-value">
                    <div
                      v-if="file.status.startsWith('Uploading')"
                      :title="'Uploading in progress'"
                      class="progress"
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :style="{
                          width: file.status.replace('Uploading...', ''),
                        }"
                        :aria-valuenow="file.status.replace('Uploading...', '')"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <span
                      v-else-if="file.status === 'Uploaded'"
                      title="Uploading Succeeded"
                    >
                      <i class="text-success fas fa-check-circle" />
                    </span>
                    <span
                      v-else-if="file.status === 'Failed'"
                      title="Uploading Failed"
                    >
                      <i class="text-danger fas fa-times-circle" />
                    </span>
                    <span v-else title="Waiting for Upload">
                      <i class="text-warning fas fa-rotate-left" />
                    </span>
                  </span>
                </div>
                <div class="list-item">
                  <span class="item-label">View:</span>
                  <span class="item-value">
                    <a
                      v-if="file.url"
                      target="_blank"
                      title="view file"
                      :href="file.url"
                    >
                      <i class="mr-2 fas fa-link" />
                    </a>
                  </span>
                </div>
                <div class="list-item">
                  <span class="item-label">Action:</span>
                  <span class="item-value">
                    <button
                      v-if="file.status !== 'Uploading'"
                      @click="deleteFile(file.fileName, file.url)"
                      @keydown.enter.prevent="
                        deleteFile(file.fileName, file.filePath)
                      "
                      class="btn btn-sm btn-danger"
                      :title="`Delete ${file.fileName}`"
                    >
                      Delete
                    </button>
                  </span>
                </div>
              </div>
            </template>
          </div>
          <div class="mt-3">
            <input
              type="file"
              id="fileUploadInput"
              class="form-control form-sm mb-2"
              @change="handleFileSelection"
              multiple
            />
            <button
              @click="uploadFile"
              class="btn btn-outline-dark"
              :disabled="!selectedFiles.length"
              :title="
                !selectedFiles.length
                  ? 'Please select a file to upload'
                  : 'Upload Files'
              "
            >
              Upload Files
              <span class="mr-2 fas fa-cloud-arrow-up" />
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  shallowRef,
  inject,
  onMounted,
  onBeforeUnmount,
} from "vue";
import axios from "axios";
import { FileUploadRepo } from "@/services/DbAccess/FileUploadRepo.js";
import { errorToast, infoToast } from "@/utils/toast-utils";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { storeToRefs } from "pinia";

const fileHeaders = ["File Name", "Status", "View", "Delete"];
const store = useOrderFormStore();
const { userStoreId, posCode } = storeToRefs(store);
const EventBus = inject("EventBus");
const files = ref([]);
const selectedFiles = shallowRef([]);
const showModal = ref(false);
const dataIsLoading = ref(true);

const modalClasses = computed(() => ({
  "modal fade show": showModal.value,
  "modal fade": !showModal.value,
}));

async function openModalFun() {
  try {
    dataIsLoading.value = true;
    showModal.value = true;
    await fetchFileList();
  } catch (error) {
    console.error("Failed to fetch history:", error);
  } finally {
    dataIsLoading.value = false;
    if (document.body) {
      document.body.style.overflow = "hidden";
    }
  }
}

async function fetchFileList() {
  try {
    if (!posCode.value) {
      errorToast("Please select Point Of Sale first");
      return;
    }
    files.value = await FileUploadRepo.list(posCode.value);
  } catch (error) {
    errorToast("Failed to fetch file list");
    console.error("Failed to fetch file list:", error);
  } finally {
    dataIsLoading.value = false;
  }
}

function closeModal() {
  showModal.value = false;
  if (document.body) {
    document.body.style.overflow = "";
  }
}

function handleFileSelection(event) {
  selectedFiles.value = event.target.files;
}

function clearSelectedFiles() {
  const input = document.getElementById("fileUploadInput");
  if (input) {
    input.value = "";
  }
  selectedFiles.value = [];
}

async function uploadFile() {
  if (!selectedFiles.value.length) {
    infoToast("No file selected");
    return;
  }

  const currentDate = Date.now();

  if (!userStoreId.value) {
    infoToast("User was not found to upload file. Please refresh the app.");
    return;
  }

  const currentFiles = Array.from(selectedFiles.value);

  for (const file of currentFiles) {
    if (!file || !file.name) continue;

    const saveFileResult = await FileUploadRepo.save(
      posCode.value,
      file.name,
      "Waiting..."
    );

    if (!saveFileResult.success) {
      console.error("Failed to save file:", saveFileResult.error);
      continue;
    }
  }

  files.value = await FileUploadRepo.list(posCode.value);
  clearSelectedFiles();

  for (const file of currentFiles) {
    await processFile(file, currentDate);
  }
}

async function processFile(file, currentDate) {
  const fileIndex = files.value.findIndex((f) => f.fileName === file.name);
  const basePath = `${
    userStoreId.value
  }/${posCode.value.toString()}/${currentDate}/${file.name}`;

  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("basePath", basePath);

    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/UploadFile`,
      formData,
      {
        withCredentials: true,
        onUploadProgress: (progressEvent) =>
          updateProgress(progressEvent, fileIndex),
      }
    );

    await handleUploadResponse(response, file, fileIndex, basePath);
  } catch (err) {
    console.error("Upload failed for:", file.name, err.message);
    await handleUploadFailure(file, fileIndex);
  } finally {
    await fetchFileList();
    EventBus.$emit("files-updated");
  }
}

function updateProgress(progressEvent, fileIndex) {
  if (progressEvent.lengthComputable) {
    const percent = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    if (files.value[fileIndex] && files.value[fileIndex].status) {
      files.value[fileIndex].status = `Uploading... ${percent}%`;
    }
  }
}

async function handleUploadResponse(response, file, fileIndex, basePath) {
  if (response.status === 200 && response.data.success) {
    const url = response.data.filePath;
    if (files.value[fileIndex]) {
      files.value[fileIndex].status = "Uploaded";
      files.value[fileIndex].url = url;

      await FileUploadRepo.save(
        posCode.value,
        file.name,
        "Uploaded",
        url,
        basePath
      );
    }
  } else {
    throw new Error("Upload failed");
  }
}

async function handleUploadFailure(file, fileIndex) {
  if (files.value[fileIndex]) {
    files.value[fileIndex].status = "Failed";
  }
  await FileUploadRepo.save(posCode.value, file.name, "Failed");
}

async function deleteFile(fileName, filePath) {
  try {
    await FileUploadRepo.delete(posCode.value, fileName);
    if (filePath) {
      await deleteFileFromServer(filePath);
    }
  } catch (err) {
    console.error("Deletion failed:", err.message);
    errorToast("Failed to delete file");
  } finally {
    files.value = await FileUploadRepo.list(posCode.value);
    EventBus.$emit("files-updated");
  }
}

async function deleteFileFromServer(filePath) {
  try {
    await axios.delete(
      `${process.env.VUE_APP_API_URL}/DeleteFile?fileUri=${filePath}`,
      {
        withCredentials: true,
      }
    );
  } catch (err) {
    console.error("API Delete file call failed:", err.message);
  }
}

onMounted(() => {
  EventBus.$on("show-upload-file-modal", openModalFun);
});

onBeforeUnmount(() => {
  EventBus.$off("show-upload-file-modal", openModalFun);
});
</script>
