// utils/messages.js

export const MESSAGES = {
  ERROR: {
    LOST_CONNECTION:
      "Lost connection to the server. Please check your network.",
    LOGIN_REQUIRED: "Please Login to Proceed.",
    INIT_FAILED: "Failed to initialize the app. Please try again.",
    OFFLINE_MODE_REFRESH:
      "Unable to update the app data. You are working on offline mode. Refresh the app.",
  },
  INFO: {
    OFFLINE_MODE: "Offline mode app is ready.",
    APPLICATION_LOAD_TIME: "Application load time",
    APPLICATION_SYNC_IN_Progress: "Application Data Sync is in Progress",
    APPLICATION_LOAD_TIME_OFFLINE: "Application load time in offline mode",
  },
  SUCCESS: {
    APP_DATA_UPDATED: "Application data has been updated.",
    APP_DATA_SYNCED: "Application data has been synced ",
  },
};
