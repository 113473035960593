import axios from "axios";
import EventBus from "@/services/eventBus.js";
import { WorkingItemRepo } from "./DbAccess/WorkingItemRepo";
import { FileUploadRepo } from "./DbAccess/FileUploadRepo";
import { errorToast, successToast } from "@/utils/toast-utils.js";
import { dataSyncService } from "@/services/dataSync";

const API_URL = process.env.VUE_APP_API_URL;

export class OrderService {
  static async submitOrder(store, orderItems, orderPdf, orderNotes) {
    try {
      const validateResponse = await this.#validateOrderPrerequisites(
        store,
        orderItems,
        orderNotes
      );
      if (!validateResponse.success) {
        throw new Error(validateResponse.message);
      }
      const simplifiedOrderItems = this.#simplifyOrderItems(orderItems);
      const attachments = await FileUploadRepo.listAsAttahcments(
        validateResponse.pos.code
      );
      if (!orderNotes || orderNotes.trim() === "") {
        orderNotes = "";
      }
      const payload = {
        storeId: validateResponse.user.storeId,
        pointOfSale: validateResponse.pos,
        orderNotes: orderNotes,
        orderItems: simplifiedOrderItems,
        attachments: attachments,
      };
      var orderCode = await this.#generateUniqueCode(payload);
      payload.orderCode = orderCode;
      payload.orderPdf = orderPdf;
      const response = await axios.post(`${API_URL}/Order`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      var orderData = response.data;
      if (orderData) {
        await this.#handleOrderSuccess(
          orderData,
          validateResponse.pos.code,
          store
        );
      }
    } catch (error) {
      this.#handleOrderError(error);
    }
  }

  static async fetchOrders({
    storeId,
    filters,
    includes,
    sort,
    pagination,
    search,
  }) {
    try {
      const response = await axios.post(
        `${API_URL}/GetOrders`,
        this.#buildRequestPayload({
          storeId,
          filters,
          includes,
          sort,
          pagination,
          search,
        }),
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
      throw error;
    }
  }

  static #buildRequestPayload({
    storeId,
    filters,
    includes,
    sort,
    pagination,
    search,
  }) {
    return {
      storeId,
      filters,
      includes: includes || {},
      sort: sort || { sortBy: "CreationDateOffset", sortOrder: "desc" },
      pagination: pagination || { pageNumber: 0, pageSize: 20 },
      search: search || { searchParams: "" },
    };
  }

  static async #generateUniqueCode(payload) {
    try {
      const payloadString = JSON.stringify(payload);
      const formattedDate = new Date().toISOString().split("T")[0];
      const data = payloadString + formattedDate;
      const encoder = new TextEncoder();
      const dataBuffer = encoder.encode(data);
      const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
    } catch (error) {
      console.error("Error generating order code: ", error);
      return "";
    }
  }

  static #simplifyOrderItems(orderItems) {
    return orderItems
      .filter(
        (item) =>
          item &&
          item.product &&
          item.product.Id &&
          item.product.ProductCode &&
          item.quantity > 0
      )
      .map((item) => ({
        id: item.product.Id,
        quantity: item.quantity,
        notes: !item.notes || item.notes.trim() === "" ? "" : item.notes,
      }));
  }

  static async #validateOrderPrerequisites(store, orderItems, orderNotes) {
    if (navigator.onLine == false) {
      return {
        user: null,
        pos: null,
        success: false,
        message:
          "Online connection with server is required to perform this action.",
      };
    }
    if (
      orderItems.length === 0 &&
      (orderNotes == null || orderNotes === "" || orderNotes.trim() === "")
    ) {
      return {
        user: null,
        pos: null,
        success: false,
        message:
          "The order doesn't contain any items or notes. Please add items or notes to submit the order.",
      };
    }
    const user = await store.getUser();
    const pos = await store.getSelectedPointOfSale();
    if (!pos) {
      return {
        user: null,
        pos: null,
        success: false,
        message: "Please reload the app/page and try again.",
      };
    }
    if (!user) {
      errorToast("Please Login again and try to submit your order.");
      return {
        user: null,
        pos: null,
        success: false,
        message: "Please Login again and try to submit your order.",
      };
    }
    return {
      user: user,
      pos: pos,
      success: true,
      message: "",
    };
  }

  static async #handleOrderSuccess(data, pointOfSaleCode, store) {
    EventBus.$emit("order-placed", data);
    successToast("Your order has been successfully placed.");
    await WorkingItemRepo.clear(pointOfSaleCode);
    await FileUploadRepo.clear(pointOfSaleCode);
    await dataSyncService.triggerSync(store, true, true);
  }

  static #handleOrderError(error) {
    if (error.response && error.response.data) {
      EventBus.$emit("order-failed", error.response.data);
      console.error("Placing order error: ", error.response.data);
      errorToast(`Order Placement Failed.`);
      return;
    }
    if (error.name === "TypeError") {
      EventBus.$emit(
        "order-failed",
        "Placing order network error: Unable to complete the request."
      );
      console.error("Placing order network error: ", error);
    } else {
      EventBus.$emit("order-failed", error.message);
      console.error("Placing order error: ", error);
    }
    errorToast(`Order Placement Failed.`);
  }
}
