// src/stores/orderFormStore.js
import { defineStore } from "pinia";
import { reactive } from "vue";
import { UserRepo } from "@/services/DbAccess/UserRepo";
import { PointsOfSaleRepo } from "@/services/DbAccess/PointOfSaleRepo";

export const useOrderFormStore = defineStore("orderForm", {
  state: () => ({
    lastEventUpdate: null,
    isUpdating: false,
    user: reactive({
      name: "",
      email: null,
      storeId: null,
      storeLogo: "",
      storeName: "",
      storeFlag: "",
      userId: "",
    }),
    pos: reactive({
      code: null,
      customerName: null,
      billingGroup: null,
      imported: false,
    }),
    syncStatus: reactive({
      success: false,
      lastSyncDateTime: Date.now(),
      isSyncing: false,
      notifyUser: false,
    }),
  }),

  getters: {
    isLoggedIn: (state) => !!state.user.email,
    hasPOS: (state) =>
      state.pos.code?.length > 0 && state.pos.customerName?.length > 0,
    posCode: (state) => state.pos.code,
    customerName: (state) => state.pos.customerName,
    userName: (state) => state.user.name,
    userId: (state) => state.user.userId,
    userEmail: (state) => state.user.email,
    userStoreId: (state) => state.user.storeId,
    storeLogo: (state) => state.user.storeLogo,
    storeName: (state) => state.user.storeName,
    storeFlag: (state) => state.user.storeFlag,
    posImported: (state) => state.pos.imported,
    syncStatusState: (state) => state.syncStatus,
  },

  actions: {
    setUser(user) {
      if (user) {
        Object.assign(this.user, user);
      } else {
        Object.assign(this.user, {
          name: "",
          email: "",
          storeId: "",
          storeLogo: "",
          storeName: "",
          storeFlag: "",
          userId: "",
        });
        this.setPOS(null);
      }
    },

    clearUser() {
      this.setUser(null);
    },

    async getUser() {
      if (!this.user.name) {
        try {
          const user = await UserRepo.getLoggedInUser();
          this.setUser(user);
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      }
      return this.user;
    },

    async saveUser(user) {
      try {
        await UserRepo.saveLoggedInUser(user);
        this.setUser(user);
      } catch (error) {
        console.error("Error saving user:", error);
      }
    },

    async clearLoggedInUser() {
      try {
        await UserRepo.clearLoggedInUser();
        this.clearUser();
      } catch (error) {
        console.error("Error clearing logged in user:", error);
      }
    },

    setPOS(pos) {
      if (pos) {
        Object.assign(this.pos, {
          code: pos.code || null,
          customerName: pos.customerName || null,
          billingGroup: pos.billingGroup || null,
          imported: pos.imported || false,
        });
      } else {
        Object.assign(this.pos, {
          code: null,
          customerName: null,
          billingGroup: null,
          imported: false,
        });
      }
    },

    setSyncStatus(status, isSyncing, dateTime) {
      Object.assign(this.syncStatus, {
        success: status,
        lastSyncDateTime: dateTime,
        isSyncing: isSyncing,
      });
    },

    async clearPointOfSale() {
      this.setPOS(null);
      try {
        await PointsOfSaleRepo.clear();
      } catch (error) {
        console.error("Error clearing point of sale:", error);
      }
    },

    async getSelectedPointOfSale(update = false) {
      if (!this.pos.code || update) {
        try {
          const result = await PointsOfSaleRepo.get(this.user.storeId);
          this.setPOS(result?.success ? result : null);
        } catch (error) {
          console.error("Error fetching point of sale:", error);
          this.setPOS(null);
        }
      }
      return this.pos;
    },

    async savePointOfSale(pos) {
      try {
        const { code, customerName, storeId } = pos || {};
        const result = await PointsOfSaleRepo.save(code, customerName, storeId);
        if (result?.success) {
          this.setPOS(result);
          return true;
        }
      } catch (error) {
        console.error("Error saving point of sale:", error);
      }
      this.setPOS(null);
      return false;
    },

    updateHistory() {
      const currentTime = Date.now();
      if (
        !this.isUpdating &&
        (!this.lastEventUpdate ||
          currentTime - this.lastEventUpdate >= 9 * 1000)
      ) {
        this.isUpdating = true;
        this.updatePointOfSalesHistory();
        this.lastEventUpdate = currentTime;
      }
    },

    async updatePointOfSalesHistory() {
      try {
        await PointsOfSaleRepo.updatePointOfSalesHistory(
          this.pos.code,
          this.user.storeId
        );
      } catch (error) {
        //console.error("Error updating event log:", error);
      } finally {
        this.isUpdating = false;
      }
    },
  },
});
