<!-- ProductSearch.vue -->
<template>
  <div class="dropdown" v-click-outside="closeDropdown">
    <input
      class="form-control form-control-sm dropdown-toggle product-search-input"
      ref="inputFieldRef"
      name="product-search-input"
      v-model="searchTerm"
      @input="handleInput"
      @focus="showDropdown = true"
      autocomplete="off"
      @keydown.up.prevent="navigateDropdown(-1)"
      @keydown.down.prevent="navigateDropdown(1)"
      @keydown.enter.prevent="selectHighlightedProduct()"
      placeholder="Search product..."
      data-toggle="dropdown"
    />
    <div
      v-if="showDropdown && results && results.length"
      class="dropdown-menu show"
    >
      <a
        href="#"
        :class="dropdownItemClass(index)"
        tabindex="0"
        v-for="(result, index) in results"
        :key="index"
        @click.prevent="selectProduct(result)"
      >
        <div class="result-text">
          <span class="text-bold">{{ result.ProductCode }}</span
          >-
          {{ result.Title }}
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from "vue";

const props = defineProps({
  searchQuery: { type: [String, Number], default: "" },
  initiateProduct: { type: Object, default: null, required: false },
  results: { type: Array, default: () => [] },
});

const emit = defineEmits(["inputChanged", "productSelected"]);

const inputFieldRef = ref(null);
const showDropdown = ref(false);
const searchTerm = ref(props.searchQuery);
const currentIndex = ref(-1);
const initialized = ref(false);

onMounted(() => {
  if (inputFieldRef.value) {
    inputFieldRef.value.focus();
  }
  showDropdown.value = false;
  initialized.value = true;
});

const handleInput = (event) => {
  showDropdown.value = true;
  emit("inputChanged", event.target.value);
};

const closeDropdown = () => {
  showDropdown.value = false;
};

const dropdownItemClass = (index) => ({
  "dropdown-item-highlighted": currentIndex.value === index,
  "dropdown-item": true,
});

const navigateDropdown = (direction) => {
  if (!props.results.length) return;
  currentIndex.value = Math.max(
    0,
    Math.min(currentIndex.value + direction, props.results.length - 1)
  );
};

const selectProduct = (result) => {
  emit("productSelected", result);
};
const selectHighlightedProduct = () => {
  const selectedProduct = props.results[currentIndex.value];
  if (selectedProduct?.Title) {
    emit("productSelected", selectedProduct);
  }
};
</script>
