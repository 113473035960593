export function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function formatDate(timestamp) {
  const date = new Date(timestamp);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")} ${String(
    date.getHours()
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
}
export function getUTCDate(dateInputString) {
  if (!dateInputString) {
    return new Date(Date.now());
  }

  let date;
  if (typeof dateInputString === "string") {
    // If the string doesn't end with 'Z', assume it's UTC and append 'Z'
    if (!dateInputString.endsWith("Z") && !dateInputString.includes("+")) {
      dateInputString += "Z";
    }
    date = new Date(dateInputString);
  } else {
    date = new Date(dateInputString);
  }

  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  );
}

export function formattedTimeDifference(dateString) {
  if (!dateString) return "";

  const now = getUTCDate();
  const then = getUTCDate(dateString);
  const diff = now - then;

  const timeUnits = [
    { unit: "month", divisor: 30 * 24 * 60 * 60 * 1000 },
    { unit: "day", divisor: 24 * 60 * 60 * 1000 },
    { unit: "hour", divisor: 60 * 60 * 1000 },
    { unit: "minute", divisor: 60 * 1000 },
    { unit: "second", divisor: 1000 },
  ];

  for (const { unit, divisor } of timeUnits) {
    const value = Math.floor(diff / divisor);
    if (value >= 1 || unit === "second") {
      if (unit === "second" && value < 1) return "just now";
      if (unit === "second" && value < 10) return "few seconds ago";
      return `${value} ${unit}${value !== 1 ? "s" : ""} ago`;
    }
  }
}

export async function withRetry(fn, name, param, retries = 5) {
  for (let attempt = 0; attempt <= retries; attempt++) {
    const result = await fn(param).catch((error) => {
      console.error(`Attempt ${attempt + 1}:`, error);
      return {
        success: false,
        message: error.message,
        numberOfRetries: attempt,
      };
    });

    if (result && result.success) {
      return {
        ...result,
        numberOfRetries: attempt,
      };
    } else {
      if (attempt < retries) {
        await new Promise((resolve) => setTimeout(resolve, 1500));
      } else {
        console.error(`Maximum retry limit reached. ${name} failed.`);
        return {
          success: false,
          message: "Maximum retry limit reached.",
          numberOfRetries: attempt,
        };
      }
    }
  }
}

export function findById(array, id) {
  if (!array || !Array.isArray(array) || array.length === 0) {
    return null;
  }

  if (id == null) {
    return null;
  }

  return array.find((item) => item && item.Id == id) || null;
}

export function formatCustomerName(name) {
  if (typeof name !== "string" || name.trim() === "") {
    return name;
  }

  return name
    .split(" ")
    .map((word) => {
      if (word.trim() === "") return word;
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ")
    .trim();
}

export function validInput(input) {
  return typeof input === "string" && input.replace(/\s+/g, "").length >= 3;
}
