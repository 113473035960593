import { MESSAGES } from "@/utils/messages.js";

export const logLoadTime = (
  isUpToDate,
  numberOfRetries,
  isProductDownloaded,
  isAUthenticatedViaApi,
  interactionStartTime,
  pdfLoadEndTime
) => {
  const endDate = new Date();
  let pageLoadStart = endDate;
  const performance = window.performance;
  if (performance) {
    const [navigationEntry] = performance.getEntriesByType("navigation");
    if (navigationEntry) {
      pageLoadStart = new Date(
        performance.timeOrigin + navigationEntry.startTime
      );
    }
  }
  const duration = ((endDate - pageLoadStart) / 1000).toFixed(3);
  let pdfLoadEndTimeDuration = 0;
  if (pdfLoadEndTime) {
    pdfLoadEndTimeDuration = ((pdfLoadEndTime - pageLoadStart) / 1000).toFixed(
      3
    );
  }
  const interactionStart = (
    (interactionStartTime - pageLoadStart) /
    1000
  ).toFixed(3);
  const apiLoadTime = (
    (endDate - pageLoadStart) / 1000 -
    interactionStart
  ).toFixed(3);

  var name = isUpToDate
    ? MESSAGES.INFO.APPLICATION_LOAD_TIME
    : MESSAGES.INFO.APPLICATION_LOAD_TIME_OFFLINE;

  if (window.appInsights) {
    window.appInsights.trackEvent({
      name: name,
      properties: {
        duration: duration,
        depndecyLoaded: apiLoadTime,
        interactionStart: interactionStart,
        numberOfRetries: numberOfRetries,
        isProductDownloaded: isProductDownloaded,
        isAUthenticatedViaApi: isAUthenticatedViaApi,
        pdfLoadEndTimeDuration: pdfLoadEndTimeDuration,
      },
    });
  } else {
    document.getElementById("app-version-id").innerText = interactionStart;
    console.log(
      name,
      " duration :",
      duration,
      " interactionStart :",
      interactionStart,
      " apiLoadTime :",
      apiLoadTime,
      " numberOfRetries :",
      numberOfRetries,
      "pdfLoadEndTimeDuration :",
      pdfLoadEndTimeDuration
    );
    return interactionStart;
  }
};
