<template>
  <div class="site-nav-bar">
    <nav v-if="isReady" class="navbar navbar-expand navbar-light bg-light mb-1">
      <a class="navbar-brand" href="#">
        <image-component
          :baseUrl="logoImageSrc"
          :width="imageWidth"
          :tab-index="0"
          title="SPCI Logo"
          :allow-pop-up-on-click="false"
        ></image-component>
      </a>

      <div
        v-if="!isMobile"
        class="navbar-text flex-grow-1 text-center"
        :class="{ 'mr-5': !isLoggedIn, 'mr-n5': isLoggedIn }"
      >
        SPCI Order Form
      </div>

      <div class="d-flex align-items-center">
        <template v-if="isLoggedIn">
          <a href="#" @click="openProductCatalog" class="nav-link mr-2">
            {{ productTitle }}<i class="fas fa-store ml-1" />
          </a>
          <a href="#" @click="openWorkingHistory" class="nav-link">
            {{ workingHistoryTitle }}<i class="fas fa-folder-open ml-1" />
          </a>
          <a class="nav-link dropdown" @click.stop="toggleDropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink-user"
              role="button"
              :aria-expanded="isDropdownOpen"
            >
              {{ userName }}
            </a>
            <ul
              class="dropdown-menu dropdown-menu-right"
              :class="{ show: isDropdownOpen }"
              v-click-outside="closeDropdown"
              aria-labelledby="navbarDropdownMenuLink-user"
            >
              <li>
                <a class="dropdown-item" href="#" @click="openOrderHistoryModal"
                  >My Order History</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="openDataSyncModal"
                  >Sync/Restore Data</a
                >
              </li>
              <li>
                <a class="dropdown-item" :href="profileUrl" target="_blank"
                  >Profile</a
                >
              </li>
              <li><a class="dropdown-item" :href="logOutUrl">Log Out</a></li>
            </ul>
          </a>
          <a
            href="#"
            title="Reload the app"
            @click="refreshClick"
            @keydown.enter="refreshClick"
            class="nav-link mr-2"
          >
            <i title="Reload the app" class="fas fa-rotate-right" />
          </a>
        </template>

        <a v-else class="nav-link font-weight-medium" :href="loginUrl">Login</a>
      </div>

      <div v-if="isMobile" class="w-100 text-center mt-2 mb-2">
        SPCI Order Form
      </div>
    </nav>
  </div>
</template>

<script setup>
import { computed, ref, inject, onMounted } from "vue";
import ImageComponent from "@/components/General/ImageComponent.vue";
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";

const EventBus = inject("EventBus");
const store = useOrderFormStore();
const { storeLogo, isLoggedIn, userName } = storeToRefs(store);
const isMobile = inject("isMobile");
const isDropdownOpen = ref(false);
const isReady = ref(false);
const emit = defineEmits([
  "modal-history-click",
  "modal-product-catalog-click",
]);

const loginUrl = ref(
  `${process.env.VUE_APP_API_loginUrl}${window.location.origin}`
);
const logOutUrl = ref(
  `${process.env.VUE_APP_API_logOutUrl}${window.location.origin}?loggedOut=true`
);
const profileUrl = ref(process.env.VUE_APP_API_Profile);
const logoImageSrc = ref(process.env.VUE_APP_defaultLogo);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const refreshClick = () => {
  location.reload();
};

const openDataSyncModal = () => {
  EventBus.$emit("show-data-sync-modal");
};
const openOrderHistoryModal = () => {
  EventBus.$emit("show-user-order-history-modal");
};
onMounted(() => {
  if (storeLogo.value && storeLogo.value.trim() !== "") {
    logoImageSrc.value = storeLogo.value;
  }

  isReady.value = true;
});

const openWorkingHistory = () => {
  EventBus.$emit("show-history-modal");
};

const openProductCatalog = () => {
  EventBus.$emit("show-product-catalog-modal");
};

const closeDropdown = () => {
  isDropdownOpen.value = false;
};

const workingHistoryTitle = computed(() =>
  !isMobile.value ? "Working History " : "History "
);
const productTitle = computed(() =>
  !isMobile.value ? "Browse Products " : "Products "
);
const imageWidth = computed(() => (isMobile.value ? 75 : 100));
</script>