<template>
  <div tabindex="-1" :class="modalClasses">
    <div
      class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"
    >
      <div class="modal-content">
        <div v-if="orderPlaced" class="modal-section">
          <div class="modal-header">
            <h6 class="modal-title">Order Placed</h6>
            <button type="button" class="btn-close" @click="closeModal" />
          </div>
          <div class="modal-body">
            <i class="icon-success fas fa-circle-check mb-3" />
            <p>
              {{ successMessage }}
            </p>
          </div>
        </div>
        <div v-else-if="orderError" class="modal-section">
          <div class="modal-header">
            <h6 class="modal-title">Problem with placing the order</h6>
            <button type="button" class="btn-close" @click="closeModal" />
          </div>
          <div class="modal-body">
            <i class="icon-error fas fa-circle-xmark mb-3" />
            <p>
              There was an issue while trying to place the order! Kindly try
              again or contact support for advice.
            </p>

            <p>
              <b>Error details: {{ errorMessage }}</b>
            </p>
          </div>
        </div>
        <div v-else-if="confirmClicked" class="modal-section">
          <div class="modal-header">
            <h6 class="modal-title">Processing</h6>
          </div>
          <div class="modal-body">
            <div class="spinner-container">
              <circles-to-rhombuses-spinner
                :animation-duration="1000"
                :circles-num="4"
                :circle-size="16"
                color="#4caf50"
              />
              <p>Processing your order...</p>
            </div>
          </div>
        </div>
        <div v-else class="modal-section">
          <div class="modal-header">
            <h6 class="modal-title">Confirm Your Order</h6>
            <button type="button" class="btn-close" @click="closeModal" />
          </div>
          <div class="modal-body">
            <p>
              Are you ready to proceed? By clicking 'Confirm', your order will
              be submitted for processing. Please ensure that all details are
              correct before proceeding. You will receive a notification once
              your order is placed.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              @click="confirmSubmit"
            >
              Confirm
            </button>
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, inject } from "vue";
import { CirclesToRhombusesSpinner } from "epic-spinners";

const emit = defineEmits([
  "modal-closed",
  "submit-order",
  "clear-wroking-items",
]);

const EventBus = inject("EventBus");
const confirmClicked = ref(false);
const orderPlaced = ref(false);
const orderError = ref(false);
const errorMessage = ref("");
const successMessage = ref("");
const props = defineProps({
  openModal: { type: Boolean, required: true },
});

const showModal = ref(false);
watch(
  () => props.openModal,
  (newValue) => {
    showModal.value = newValue;
    newValue ? null : closeModal();
  }
);

const modalClasses = computed(() => ({
  "modal fade show": showModal.value,
  "modal fade": !showModal.value,
}));

const confirmSubmit = () => {
  confirmClicked.value = true;
  emit("submit-order");
};

const closeModal = () => {
  confirmClicked.value = false;
  orderPlaced.value = false;
  orderError.value = false;
  errorMessage.value = "";
  successMessage.value = "";
  emit("modal-closed");
};

const onOrderPlaced = (data) => {
  confirmClicked.value = false;
  orderError.value = false;
  orderPlaced.value = true;
  successMessage.value = data;
};
const onOrderFailed = (error) => {
  confirmClicked.value = false;
  orderError.value = true;
  orderPlaced.value = false;
  errorMessage.value = error;
};
onMounted(() => {
  EventBus.$on("order-placed", onOrderPlaced);
  EventBus.$on("order-failed", onOrderFailed);
});

onBeforeUnmount(async () => {
  EventBus.$off("order-placed", onOrderPlaced);
  EventBus.$off("order-failed", onOrderFailed);
});
</script>
<style scoped>
.icon-success,
.icon-error {
  font-size: 20px !important;
}
</style>