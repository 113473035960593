document.addEventListener("app-loaded", function () {
  var loader = document.getElementById("loader");
  var app = document.getElementById("app");
  if (loader) {
    loader.style.opacity = "0";
    setTimeout(function () {
      loader.style.display = "none";
      app.style.opacity = "1";

      var scripts = [
        {
          src: "https://synergyprosales.blob.core.windows.net/public/js/solid.min.js.gz",
          crossorigin: "anonymous",
          async: true,
          referrerpolicy: "no-referrer",
        },
        {
          src: "https://synergyprosales.blob.core.windows.net/public/js/fontawesome.min.js.gz",
          crossorigin: "anonymous",
          referrerpolicy: "no-referrer",
          async: true,
        },
      ];

      scripts.forEach(function (scriptInfo) {
        var script = document.createElement("script");
        script.src = scriptInfo.src;
        script.async = scriptInfo.async || false;
        if (scriptInfo.crossorigin) script.crossOrigin = scriptInfo.crossorigin;
        if (scriptInfo.referrerpolicy)
          script.referrerPolicy = scriptInfo.referrerpolicy;
        document.body.appendChild(script);
      });
    }, 50);
  }
});
