import Bowser from "bowser";
import db from "@/services/DbAccess/dbConfig";
import { generateUUID } from "../utils/utils";

const generateSessionId = async () => {
  try {
    const deviceInfo = await db.DeviceInfo.get(1);
    if (deviceInfo?.sessionId) {
      return deviceInfo.sessionId;
    }
    const sessionId = generateUUID();
    await db.DeviceInfo.put({ id: 1, sessionId });
    return sessionId;
  } catch (error) {
    console.error("Error getting/generating session ID:", error);
    return null;
  }
};

const getIOSVersion = (userAgent) => {
  const matches = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return matches ? matches.slice(1, 4).join(".").replace(/\.$/, "") : null;
};

const parseDeviceInfo = (browser, sessionId) => {
  const { name: browserName, version: browserVersion } = browser.browser;
  const { name: osName, versionName: osVersion } = browser.os;
  const { type: platformType } = browser.platform;

  const deviceInfo = {
    sessionId,
    browser: browserName,
    browserVersion,
    clientOS: `${osName} ${osVersion || ""}`.trim(),
    platform: platformType,
    appVersion: process.env.REACT_APP_VERSION,
  };

  if (osName === "iOS") {
    const iosVersion = getIOSVersion(navigator.userAgent);
    if (iosVersion) {
      deviceInfo.clientOS = `${osName} ${iosVersion}`;
    }
  }

  return deviceInfo;
};

export const getDeviceInfo = async () => {
  try {
    const sessionId = await generateSessionId();
    if (!sessionId) {
      throw new Error("Failed to generate session ID");
    }
    const browser = Bowser.parse(window.navigator.userAgent);
    return parseDeviceInfo(browser, sessionId);
  } catch (error) {
    console.error("Error getting device info:", error);
    return null;
  }
};
