<template>
  <div v-if="hasPOS">
    <items-table :pointOfSaleCode="posCode" />
    <items-summary
      :pointOfSaleCode="posCode"
      :pdfMakeSetupLoaded="pdfMakeSetupLoaded"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";
import ItemsTable from "@/components/Products/ItemsTable.vue";
import ItemsSummary from "@/components/Products/ItemsSummary.vue";
const emit = defineEmits(["open-product-catalog"]);
const props = defineProps({
  pdfMakeSetupLoaded: { type: Boolean, required: true },
});

const store = useOrderFormStore();
const { hasPOS, posCode } = storeToRefs(store);
</script>
