<template>
  <div v-if="showModal" :class="modalClasses" @click="closeModal">
    <div class="modal-wrapper" @click.stop>
      <img
        :src="imageUrl"
        @click.stop="toggleZoom"
        class="modal-content"
        :class="{ zoomed: isZoomed }"
      />
    </div>
    <span class="close" @click.stop="closeModal">&times;</span>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, watch, onMounted, inject } from "vue";

const EventBus = inject("EventBus");
const showModal = ref(false);
const imageUrl = ref("");
const isZoomed = ref(false);

const modalClasses = computed(() => ({
  "modal fade show": showModal.value,
  "modal fade": !showModal.value,
}));

const openModalFunction = (url) => {
  imageUrl.value = url;
  showModal.value = true;
  window.addEventListener("keydown", handleEscape);
};

const toggleZoom = () => {
  isZoomed.value = !isZoomed.value;
};

const closeModal = () => {
  isZoomed.value = false;
  showModal.value = false;
  window.removeEventListener("keydown", handleEscape);
};

onMounted(() => {
  EventBus.$on("show-image-modal", openModalFunction);
});

onBeforeUnmount(() => {
  EventBus.$off("show-image-modal", openModalFunction);
  window.removeEventListener("keydown", handleEscape);
});
const handleEscape = (event) => {
  if (event.key === "Escape") {
    closeModal();
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-wrapper {
  position: relative;
}

.close {
  position: absolute;
  top: 5%;
  right: 5%;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  text-align: center;
  z-index: 2;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 600px;
  cursor: zoom-in;
}
.modal-content.zoomed {
  transform: scale(1.5);
  cursor: zoom-out;
}

@media only screen and (max-width: 767px) {
  .modal-content {
    width: 65%;
  }
  .close {
    top: 15%;
    right: 3%;
    font-size: 20px;
  }
}
</style>
