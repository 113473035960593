export default {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (event.target.tagName.toLowerCase() === "input") {
        return;
      }

      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};
