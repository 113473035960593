<template>
  <textarea
    :value="value"
    ref="textareaField"
    class="form-control form-control-sm"
    :rows="value ? 2 : 1"
    :placeholder="placeholder"
    @input="handleInput"
    :dir="textDirection"
  ></textarea>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";

const props = defineProps({
  value: { type: String, required: true },
  placeholder: String,
});
watch(
  () => props.value,
  (newValue) => {
    updateTextDirection(newValue);
  }
);

const textDirection = ref("ltr");
const emit = defineEmits(["inputChanged"]);
const textareaField = ref(null);

const handleInput = (event) => {
  emit("inputChanged", event.target.value);
};

function updateTextDirection(value) {
  const arabicPattern = /[\u0600-\u06FF]/;
  textDirection.value = arabicPattern.test(value) ? "rtl" : "ltr";
}
const focusInput = () => {
  textareaField.value.focus();
};
onMounted(() => {
  updateTextDirection(props.value);
});
defineExpose({
  focusInput,
});
</script>
