const MAX_RETRIES = 35;
const RETRY_INTERVAL = 500;

const convertArgsToString = (args) => {
  if (!args || args.length === 0) return "";
  return args
    .map((arg) => {
      if (typeof arg === "object") {
        try {
          return JSON.stringify(arg, getCircularReplacer());
        } catch (e) {
          return "[Object with circular reference]";
        }
      }
      return String(arg);
    })
    .join(" ");
};

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return "[Circular]";
      }
      seen.add(value);
    }
    return value;
  };
};

function makeSerializable(properties) {
  const serializableProps = {};
  for (const [key, value] of Object.entries(properties)) {
    try {
      if (typeof value === "object") {
        serializableProps[key] = convertArgsToString([value]);
      } else {
        serializableProps[key] = String(value);
      }
    } catch (e) {
      serializableProps[key] = "[Unserializable]";
    }
  }
  return serializableProps;
}

function waitForAppInsights(app, retryCount = 0) {
  if (window.appInsights) {
    setupAppInsights(app);
  } else if (retryCount < MAX_RETRIES) {
    setTimeout(() => waitForAppInsights(app, retryCount + 1), RETRY_INTERVAL);
  } else {
    if (process.env.NODE_ENV === "production") {
      console.warn("AppInsights not initialized");
    }
  }
}

function setupAppInsights(app) {
  const appInsights = window.appInsights;
  function trackError(error, customProperties = {}) {
    let errorMessage;
    let errorStack;

    if (error instanceof Error) {
      errorMessage = error.message;
      errorStack = error.stack;
    } else if (typeof error === "object") {
      errorMessage = convertArgsToString([error]);
      errorStack = null;
    } else {
      errorMessage = String(error);
      errorStack = null;
    }
    const appVersion = process.env.VUE_APP_VERSION || "Unknown";
    const baseProperties = { errorStack, appVersion };
    const allProperties = { ...baseProperties, ...customProperties };
    const serializableProperties = makeSerializable(allProperties);
    const trackedError =
      error instanceof Error ? error : new Error(errorMessage);
    appInsights.trackException({
      exception: trackedError,
      properties: serializableProperties,
      severityLevel: 3,
    });
  }

  app.config.errorHandler = (err, vm, info) => {
    trackError(err, {
      componentName: vm?.$options?.name,
      info: convertArgsToString([info]),
      source: "Vue",
      vueVersion: app.version,
      customeName: err?.name,
      customeMessage: err?.message,
    });
    console.warn("Error tracked by appInsights", err);
  };

  const originalConsoleError = console.error;
  console.error = (...args) => {
    const errorMessage = convertArgsToString(args);
    trackError(new Error(errorMessage), {
      source: "console.error",
      originalArgs: errorMessage,
      customMessage: errorMessage,
    });
    originalConsoleError.apply(console, args);
  };

  window.addEventListener("unhandledrejection", (event) => {
    trackError(event.reason, {
      source: "unhandledRejection",
      promiseDetails: convertArgsToString([event.promise]),
    });
  });
}

export function initAppInsights(app) {
  if (window.appInsights) {
    setupAppInsights(app);
  } else {
    waitForAppInsights(app);
  }
}
