// WorkingItemRepo.js
import db from "./dbConfig";

const TABLE_EXPIRY_TIME = {
  WorkingItems: 60 * 24 * 60 * 60 * 1000,
};

export class WorkingItemRepo {
  static async save(item) {
    item.createdAt = Date.now();
    item.searchResults = [];
    await db.WorkingItems.put(item);
  }

  static async delete(itemId) {
    return await db.WorkingItems.delete(itemId);
  }

  static async list(pointOfSaleCode) {
    if (!pointOfSaleCode) return [];
    const uppercaseCode = pointOfSaleCode.toUpperCase();
    const allItems = await db.WorkingItems.where("pointOfSaleCode")
      .equals(uppercaseCode)
      .toArray();

    const uniqueItemsMap = new Map();
    for (const item of allItems) {
      if (item.product && !uniqueItemsMap.has(item.product.Id)) {
        uniqueItemsMap.set(item.product.Id, item);
      }
    }

    const uniqueItems = Array.from(uniqueItemsMap.values());
    return uniqueItems;
  }

  static async clear(pointOfSaleCode) {
    return await db.WorkingItems.where("pointOfSaleCode")
      .equals(pointOfSaleCode)
      .delete();
  }

  static async clearExpiredWorkingItems() {
    try {
      await db.WorkingItems.where("createdAt")
        .below(Date.now() - TABLE_EXPIRY_TIME.WorkingItems)
        .delete();
    } catch (error) {
      console.error("Error clear old WorkingItems: ", error);
    }
  }
}
