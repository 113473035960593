import Dexie from "dexie";

const db = new Dexie("SPCIOrderFormDB");
db.version(34).stores({
  ProductsTable:
    "ProductCode, Title, BrandId, CategoryId, [BrandId+CategoryId]",
  WorkingItems: "++id, pointOfSaleCode, *createdAt",
  UserDetails: "++id",
  PointsOfSale:
    "code, customerName,storeId,[code+storeId] ,imported,*createdAt",
  SelectePointOfSale: "++id,storeId",
  OrderNote: "++id, &pointOfSaleCode, note, *createdAt",
  Brands: "Id, Name",
  Categories: "Id, Name",
  BrandCategories: "BrandId, *CategoryIds",
  CategoryBrands: "CategoryId, *BrandIds",
  heartbeats: "id, timestamp",
  WorkingHistoryPointOfSale:
    "++id, [code+storeId], customerName, code, storeId, *createdAt",
  Files:
    "++id,[pointOfSaleCode+fileName], pointOfSaleCode, fileName,  *createdAt",
  SyncHeartBeat: "++id, timestamp",
  DeviceInfo: "++id, sessionId",
});
export default db;
