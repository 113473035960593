// UserRepo.js
import db from "./dbConfig";

export class UserRepo {
  static async saveLoggedInUser(user) {
    await db.UserDetails.clear();
    await db.UserDetails.put(user);

    return { success: true };
  }

  static async getLoggedInUser() {
    return await db.UserDetails.limit(1).first();
  }
  static async clearLoggedInUser() {
    await db.UserDetails.clear();
    return { success: true };
  }
}
