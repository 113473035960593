import axios from "axios";
import { dataSyncService } from "@/services/dataSync";

const CONFIG = {
  heartbeatThreshold: 30000,
  timeoutDuration: 8000,
  healthCheckUrl:
    "https://synergyprosales.blob.core.windows.net/public/General/710bbe80-c177-4adc-be9f-2861eb6f5425-0.jpg",
};

class ConnectivityService {
  static #monitoringIntervalId = null;

  static #dispatchConnectivityEvent(isOnline) {
    document.dispatchEvent(
      new CustomEvent("connectivity-status-changed", { detail: { isOnline } })
    );
  }

  static async #checkConnectivity(store) {
    const isOnline = navigator.onLine ? await this.#isOnlineService() : false;
    this.#dispatchConnectivityEvent(isOnline);
    await dataSyncService.triggerSync(store, isOnline);
  }

  static async #isOnlineService() {
    if (!navigator.onLine) return false;

    try {
      return await this.#performHealthCheckWithTimeout();
    } catch (error) {
      console.warn("Health request check failed:", error);
      return false;
    }
  }

  static async #performHealthCheckWithTimeout() {
    const controller = new AbortController();
    const timeoutId = setTimeout(
      () => controller.abort(),
      CONFIG.timeoutDuration
    );

    try {
      const response = await axios.head(
        CONFIG.healthCheckUrl,
        this.#getAxiosConfig(controller)
      );
      return response.status === 200;
    } catch (error) {
      this.#handleHealthCheckError(error);
      return false;
    } finally {
      clearTimeout(timeoutId);
    }
  }

  static #getAxiosConfig(controller) {
    return {
      signal: controller.signal,
      timeout: CONFIG.timeoutDuration,
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
      params: { _: new Date().getTime() },
    };
  }

  static #handleHealthCheckError(error) {
    if (axios.isCancel(error)) {
      console.error("Request HealthCheck has timed out");
    } else {
      console.error("Error performing health check:", error);
    }
  }

  static async isOnline() {
    return this.#isOnlineService();
  }

  static startMonitoring(store) {
    let intervalDuration = CONFIG.heartbeatThreshold;
    if (this.#monitoringIntervalId === null) {
      this.#monitoringIntervalId = setInterval(
        () => this.#checkConnectivity(store),
        intervalDuration
      );
    } else {
      console.log("Monitoring is already started.");
    }
  }

  static stopMonitoring() {
    if (this.#monitoringIntervalId !== null) {
      clearInterval(this.#monitoringIntervalId);
      this.#monitoringIntervalId = null;
    }
  }
}

export { ConnectivityService };
