<template>
  <td v-if="hasPOS">
    <input-field
      v-model="localQuantity"
      @inputChanged="updateQuantityField"
      ref="inputFieldRef"
      class="input-width"
      name="quantity-input"
      :value="localQuantity"
      :min-value="0"
      autocomplete="off"
    />
  </td>
  <td>
    <div class="mt-2">
      <a
        role="button"
        href="#"
        class="product-code"
        :title="`Show ${product.ProductCode} image`"
        @click="openImageModal(product?.MainPhotoUrl)"
      >
        {{ product.ProductCode }}
      </a>
    </div>
  </td>
  <td>
    <div class="mt-2">
      {{ product.Title }}
      <VTooltip
        v-if="hasPOS && hasOrderDetails"
        :triggers="['click', 'hover']"
        placement="top"
      >
        <span>
          <i class="fa-solid fa-chart-simple text-primary" />
        </span>
        <template #popper>
          <div v-html="tooltipContent"></div>
        </template>
      </VTooltip>
    </div>
  </td>
  <td v-if="hasPOS">
    <button
      v-if="localQuantity > 0"
      ref="popoverButton"
      class="btn btn-sm pop-over-button mt-2"
      @click="togglePopover"
    >
      <span><i class="fas fa-comment text-primary" /></span>
    </button>

    <note-popover
      :target="popoverButton"
      :title="product.Title"
      :isVisible="isPopoverVisible"
      @update:isVisible="isPopoverVisible = $event"
    >
      <textarea
        class="form-control pop-over-text-area"
        v-model="localNotes"
      ></textarea>
      <button class="btn btn-success" @click="saveNote">Save</button>
    </note-popover>
  </td>
  <td v-if="!isMobile">
    <image-component
      :baseUrl="product?.MainPhotoUrl"
      :width="45"
      :height="45"
      :tab-index="0"
      :title="product?.Title"
      :allow-pop-up-on-click="true"
    />
  </td>
</template>

<script setup>
import { ref, watch, inject, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";
import InputField from "@/components/General/InputField.vue";
import NotePopover from "@/components/Products/ProductCatalog/NotePopover.vue";
import ImageComponent from "@/components/General/ImageComponent.vue";

const props = defineProps({
  product: { type: Object, required: true },
  index: { type: Number, required: true },
  selectedItem: { type: Object, required: false },
  orderDetails: { type: Array, default: () => [] },
});

const emit = defineEmits(["product-updated"]);

const store = useOrderFormStore();
const { hasPOS } = storeToRefs(store);

const isMobile = inject("isMobile");
const EventBus = inject("EventBus");

const localQuantity = ref(0);
const popoverButton = ref(null);
const isPopoverVisible = ref(false);
const localNotes = ref("");

const hasOrderDetails = computed(
  () => props.orderDetails && props.orderDetails.length > 0
);

const tooltipContent = computed(() => {
  if (!hasOrderDetails.value) return "";

  const formatOrderDetail = (detail) => `
    <div class="order-detail-tooltip">
      <b>${detail.orderDate}</b><br>
      Quantity: <b>${detail.quantity}</b>
    </div>
  `;

  const detailsHtml = props.orderDetails.map(formatOrderDetail).join("<br>");

  return `
    <div>
      <b>Order History</b>
      ${detailsHtml}
    </div>
  `;
});

watch(
  () => props.selectedItem,
  (newValue) => {
    localQuantity.value = newValue ? newValue.quantity : 0;
    localNotes.value = newValue ? newValue.notes : "";
  }
);

const updateQuantityField = (value) => {
  EventBus.$emit("product-catalog-change", {
    quantity: value,
    fieldType: "quantity",
    productCode: props.product.ProductCode,
  });
  setTimeout(() => emit("product-updated"), 50);
};

const togglePopover = () => {
  isPopoverVisible.value = !isPopoverVisible.value;
};

const saveNote = () => {
  EventBus.$emit("product-catalog-change", {
    notes: localNotes.value,
    fieldType: "notes",
    productCode: props.product.ProductCode,
  });
  setTimeout(() => emit("product-updated"), 50);
  isPopoverVisible.value = false;
};

const openImageModal = (url) => {
  EventBus.$emit("show-image-modal", url);
};

onMounted(() => {
  localQuantity.value = props.selectedItem ? props.selectedItem.quantity : 0;
  localNotes.value = props.selectedItem ? props.selectedItem.notes : "";
});
</script>