<template>
  <div :class="modalClasses" tabindex="-1">
    <div
      class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Working History</h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <p class="text-muted">
            View and manage your working history across different Points of
            Sale.
          </p>
          <input
            v-model="searchTerm"
            type="text"
            class="form-control mb-3"
            name="search-by-pos"
            placeholder="Search by Point of Sale code"
            @input="getFilteredRecords"
            autocomplete="off"
          />
          <div class="item-list">
            <div class="item-header">
              <div
                v-for="header in historyHeaders"
                :key="header"
                class="header-item"
              >
                {{ header }}
              </div>
            </div>
            <template v-if="dataIsLoading">
              <div class="item-row loading">
                <div class="loading-message">
                  Loading...
                  <i class="fa fa-arrows-rotate rotate-animation"></i>
                </div>
              </div>
            </template>
            <template v-else-if="workingHistory.length === 0">
              <div class="item-row no-data">
                <div class="no-data-message">
                  🤔 No records found
                  <span v-if="searchTerm">
                    for query: <b>{{ searchTerm.toUpperCase() }}</b>
                  </span>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="record in sortedWorkingHistory"
                :key="record.code"
                :class="[
                  'item-row',
                  { 'current-row': selectedPOS === record.code },
                ]"
              >
                <div
                  v-for="(value, key) in getRecordDetails(record)"
                  :key="key"
                  class="list-item"
                  :title="selectedPOS === record.code ? 'Current POS' : ''"
                >
                  <span class="item-label">{{ key }}:</span>
                  <span
                    v-if="key !== 'Action'"
                    class="item-value"
                    v-html="value"
                  ></span>
                  <span v-else class="item-value">
                    <button
                      v-if="selectedPOS !== record.code"
                      class="btn btn-sm btn-primary"
                      title="Switch Selected Point of Sale"
                      @click="switchSelectedPos(record.code)"
                    >
                      Switch
                    </button>
                    <span v-else>Current</span>
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, inject, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { PointsOfSaleRepo } from "@/services/DbAccess/PointOfSaleRepo.js";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { formattedTimeDifference } from "@/utils/utils";

const store = useOrderFormStore();
const { userStoreId, posCode } = storeToRefs(store);
const EventBus = inject("EventBus");

const showModal = ref(false);
const searchTerm = ref("");
const selectedPOS = ref("");
const workingHistory = ref([]);
const dataIsLoading = ref(true);

const modalClasses = computed(() => ({
  "modal fade show": showModal.value,
  "modal fade": !showModal.value,
}));

const historyHeaders = [
  "Time",
  "Point Of Sale Code",
  "Point Of Sale Name",
  "Action",
];

const sortedWorkingHistory = computed(() => {
  return [...workingHistory.value].sort((a, b) => {
    if (a.code === selectedPOS.value) return -1;
    if (b.code === selectedPOS.value) return 1;
    return 0;
  });
});

watch(posCode, (newPosCode) => {
  selectedPOS.value = newPosCode || "";
});

async function openModalFunc() {
  try {
    showModal.value = true;
    dataIsLoading.value = true;
    workingHistory.value = await fetchWorkingHistory();
  } catch (error) {
    console.error("Failed to fetch history:", error);
  } finally {
    dataIsLoading.value = false;
    if (document.body) {
      document.body.style.overflow = "hidden";
    }
  }
}

function closeModal() {
  showModal.value = false;
  if (document.body) {
    document.body.style.overflow = "";
  }
}

function switchSelectedPos(code) {
  EventBus.$emit("switch-selected-pos", code);
  closeModal();
}

async function getFilteredRecords() {
  dataIsLoading.value = true;
  workingHistory.value = await fetchWorkingHistory(searchTerm.value);
  dataIsLoading.value = false;
}

async function fetchWorkingHistory(search = "") {
  return PointsOfSaleRepo.listHistory(search, 20, userStoreId.value);
}

function getRecordDetails(record) {
  return {
    Time: formattedTimeDifference(record.createdAt),
    "Point Of Sale Code": record.code.toUpperCase(),
    "Point Of Sale Name": record.customerName,
    Action: "button",
  };
}
onMounted(() => {
  EventBus.$on("show-history-modal", openModalFunc);
});
onBeforeUnmount(() => {
  EventBus.$off("show-history-modal", openModalFunc);
});
</script>