import axios from "axios";
import { ProductRepo } from "./DbAccess/ProductRepo";
import { PointsOfSaleRepo } from "./DbAccess/PointOfSaleRepo";
import { infoToast } from "@/utils/toast-utils.js";

const API_URL = process.env.VUE_APP_API_URL;
const APP_VERSION = process.env.VUE_APP_VERSION;
export class StoreService {
  static async initializeProducts(timeout = 6000) {
    if (navigator.onLine === false) {
      return { success: true, signInStatus: false };
    }
    try {
      const response = await Promise.race([
        axios.get(`${API_URL}/Products`, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("timeout")), timeout)
        ),
      ]);

      const storeCatalog = response.data;
      if (!storeCatalog || !storeCatalog.StoreProducts) {
        return { success: true, dataLoaded: false };
      }

      await Promise.all([
        ProductRepo.importProducts(storeCatalog.StoreProducts),
        ProductRepo.importBrands(
          storeCatalog.Brands,
          storeCatalog.StoreProducts
        ),
        ProductRepo.importCategories(
          storeCatalog.Categories,
          storeCatalog.StoreProducts
        ),
      ]);

      return { success: true, dataLoaded: true };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return { success: true, dataLoaded: false };
      }

      return { success: false, dataLoaded: false };
    }
  }

  static async checkUserAuthentication(store, timeout = 3000) {
    if (navigator.onLine === false) {
      return { success: true, signInStatus: false };
    }
    try {
      const response = await Promise.race([
        axios.get(`${API_URL}/Auth`, {
          withCredentials: true,
        }),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("timeout")), timeout)
        ),
      ]);

      const data = response.data;
      const userData = StoreService.mapUserData(data);
      await store.saveUser(userData);
      PointsOfSaleRepo.import(data.pointOfSales).catch((error) => {
        console.error(
          "checkUserAuthentication Failed to import points of sale:",
          error
        );
      });

      StoreService.setAuthenticatedUserContext(userData);
      return { success: true, signInStatus: true, userEmail: userData.email };
    } catch (error) {
      console.error("checkUserAuthentication Error:", error);
      return StoreService.handleAuthError(store, error);
    }
  }

  static async setAuthenticatedUserContext(user) {
    try {
      if (window.appInsights) {
        window.appInsights.setAuthenticatedUserContext(
          user.email,
          `${user.storeName}-${APP_VERSION}`,
          true
        );
        window.appInsights.addTelemetryInitializer((envelope) => {
          envelope.data = envelope.data || {};
          envelope.data.appVersion = APP_VERSION;
        });
      }
    } catch (error) {
      console.error("Error setting user context in App Insights:", error);
    }
  }

  static mapUserData(data) {
    return {
      email: data.email,
      name: data.name,
      storeId: data.storeId,
      isAuthenticated: true,
      storeName: data.storeName,
      storeLogo: data.storeLogo,
      storeFlag: data.storeFlag,
      id: data.id,
      userId: data.userId,
      success: true,
    };
  }

  static async handleAuthError(store, error) {
    if (error.response && error.response.status === 401) {
      await store.clearLoggedInUser();
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      if (searchParams.has("loggedOut")) {
        infoToast("Successfully Signed Out.");
      }
      return { success: true, signInStatus: false };
    }

    return { success: false, signInStatus: false };
  }
}
